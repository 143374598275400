import React, { useState } from "react";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import InventoryIcon from '@mui/icons-material/Inventory';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "./icons/Dashboard/Dashboard-icon";
import UsersIcon from "./icons/Users/Users-icon";
import BusinessesIcon from "./icons/Businesses/Businesses-icon";
import BanksIcon from "./icons/Banks/Banks-icon";
import ConvenienceIcon from "./icons/Convenience/Convenience-icon";
import ZedPocketIcon from "./icons/ZedPocket/ZedPocket-icon";
import TerminalsIcon from "./icons/Terminals/Terminals-icon";
import ReportsIcon from "./icons/Reports/Rerports-icon";
import ConfigurationsIcon from "./icons/Configurations/Configurations-icon";
import AboutIcon from "./icons/About/About-icon";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
export default function SidebarList(props) {
  const [usersOpen, setListOpen] = useState(false);
  const [businessesOpen, setBusinessesOpen] = useState(false);
  const [zedOpen, setZedOpen] = useState(false);
  const [openPayables, setOpenPayables] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [productOpen ,setProductOpen] = useState(false)
  const [configurationOpen, setConfigurationOpen] = useState(false);
  const [terminalOpen, setTerminalOpen] = useState(false);

  const open = props?.open;
  const navigate = useNavigate();

  const handleList = () => {
    setListOpen(!usersOpen);
  };

  const handlePayableOpen = () => {
    setOpenPayables(!openPayables);
  };

  const handleProducts = ()=>setProductOpen(!productOpen)

  const handleList2 = () => {
    setBusinessesOpen(!businessesOpen);
  };

  const handleZed = () => {
    setZedOpen(!zedOpen);
  };

  const handleReports = () => {
    setReportOpen(!reportOpen);
  };

  const handleConfigurations = () => {
    setConfigurationOpen(!configurationOpen);
  };

  const handleTerminal = () => {
    setTerminalOpen(!terminalOpen);
  };

  const currentPath = window.location.pathname;

  // console.log(`currentPath ... ${currentPath} `);

  let zedRed = "#dc3545";
  let zedBlue = "#032541";

  const Group = localStorage.getItem("group");

  const isAdmin = Group && Group === "Admin";
  const isPartner = Group && Group === "Partner";

  return (
    <>
      <List sx={{ marginTop: 2 }}>
        {(isAdmin || isPartner) && (
          <ListItem key={"Dashboard"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/adminDashboard")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <DashboardCustomizeIcon
                sx={{
                  color: currentPath === "/adminDashboard" ? zedRed : zedBlue,
                }}
              /> */}
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Dashboard"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color: currentPath === "/adminDashboard" ? zedRed : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}

        {(isAdmin || isPartner) && (
          <ListItem key={"Users"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleList}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <GroupIcon
                sx={{
                  color:
                    currentPath === "/UserGroups" ||
                    currentPath === "/EditUserGroup" ||
                    currentPath === "/AddUserGroup"
                      ? zedRed
                      : zedBlue,
                }}
              /> */}
                <UsersIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Users"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color:
                    currentPath === "/UserGroups" ||
                      currentPath === "/EditUserGroup" ||
                      currentPath === "/AddUserGroup" ||
                      currentPath === "/Customers2"
                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
              {usersOpen ? (
                <ExpandLess
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              )}
            </ListItemButton>
            <Collapse in={usersOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => navigate("/SuperAdminUsers")}
                >
                  <ListItemText
                    disableTypography
                    primary={"Users"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/SuperAdminUsers" ||
                          currentPath === "/EditUser"
                          ? // currentPath === "/AddUserGroup"
                          zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                {isAdmin && (
                  <ListItemButton
                    sx={{ pl: 9 }}
                    onClick={() => navigate("/UserGroups")}
                  >
                    <ListItemText
                      disableTypography
                      primary={"User Groups"}
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color:
                          currentPath === "/UserGroups" ||
                            currentPath === "/EditUserGroup" ||
                            currentPath === "/AddUserGroup"
                            ? zedRed
                            : zedBlue,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                )}
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => navigate("/Customers2")}
                >
                  <ListItemText
                    disableTypography
                    primary={"Customers"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: currentPath === "/Customers2" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
        )}

        {(isAdmin || isPartner) && (
          <ListItem key={"Businesses"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleList2}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <BusinessIcon
                sx={{
                  color:
                    currentPath === "/Businesses" ||
                    currentPath === "/EditBusiness" ||
                    currentPath === "/CreateBusiness" ||
                    currentPath === "/ViewModules" ||
                    currentPath === "/ViewBranches"
                      ? zedRed
                      : zedBlue,
                }}
              /> */}
                <BusinessesIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Businesses"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color:
                    currentPath === "/Businesses" ||
                      currentPath === "/EditBusiness" ||
                      currentPath === "/CreateBusiness" ||
                      currentPath === "/ViewModules" ||
                      currentPath === "/ViewBranches"
                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
              {businessesOpen ? (
                <ExpandLess
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              )}
            </ListItemButton>
            <Collapse in={businessesOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => navigate("/Businesses")}
                >
                  <ListItemText
                    disableTypography
                    primary={"Businesses"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/Businesses" ||
                          currentPath === "/EditBusiness" ||
                          currentPath === "/CreateBusiness" ||
                          currentPath === "/ViewModules" ||
                          currentPath === "/ViewBranches"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                {isPartner ? <>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => navigate("/partner/regions")}
                >
                  <ListItemText
                    disableTypography
                    primary={"Regions"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                          currentPath === "/partner/regions"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => navigate("/partner/branches")}
                >
                  <ListItemText
                    disableTypography
                    primary={"Branches"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                          currentPath === "/partner/branches" 
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => navigate("/partner/salesperson")}
                >
                  <ListItemText
                    disableTypography
                    primary={"Sales People"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                          currentPath === "/partner/salesperson"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                </>:null}
              </List>
            </Collapse>
          </ListItem>
        )}
        {isAdmin && (
          <ListItem key={"Banks"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/Banks")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <AccountBalanceIcon
                sx={{
                  color:
                    currentPath === "/Banks" ||
                    currentPath === "/EditBank" ||
                    currentPath === "/AddBank"
                      ? zedRed
                      : zedBlue,
                }}
              /> */}
                <BanksIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Banks"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color:
                    currentPath === "/Banks" ||
                      currentPath === "/EditBank" ||
                      currentPath === "/AddBank"
                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {isAdmin && (
          <ListItem key={"Delete_Request"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/delete_requests_admin")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DeleteForeverIcon style={{
                  color: currentPath === "/delete_requests_admin"

                    ? zedRed
                    : zedBlue
                }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Delete Request"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color:
                    currentPath === "/delete_requests_admin"

                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {(isAdmin) && (
          <ListItem
            key={"Payables Admin"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handlePayableOpen}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LocalLibraryIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Payables Admin"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color:
                    currentPath === "/payables/admin/transactions"
                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
              {openPayables ? (
                <ExpandLess
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              )}
            </ListItemButton>
            <Collapse in={openPayables} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/payables/admin/transactions");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Transactions"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/payables/admin/transactions"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
        )}
        {(isAdmin || isPartner) &&  (
          <ListItem
            key={"Convenience Fee Mgt"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/feeconfigs")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <LocalAtmIcon
                sx={{
                  color:
                    currentPath === "/ConvenienceFeeMgt" ? zedRed : zedBlue,
                }}
              /> */}
                <ConvenienceIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Fee Configs"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color: currentPath === "/feeconfigs" ? zedRed : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {/* 
        <ListItem
          key={"Annual Maintenance Fee"}
          disablePadding
          sx={{ display: "block" }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate("/AnnualMaintenanceFee")}
          > */}
        {/* <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            > */}
        {/* <ManageAccountsIcon sx={{ color: "#032541" }} /> */}
        {/* <AnnualMaintenanceIcon />
            </ListItemIcon> */}
        {/* <ListItemText
              disableTypography
              primary={"Annual Maintenance Fee"}
              sx={{
                fontFamily: "Poppins",
                fontSize: 12,
                fontWeight: "normal",
                fontStyle: "normal",
                textAlign: "left",
                color:
                  currentPath === "/AnnualMaintenanceFee" ? zedRed : zedBlue,
                opacity: open ? 1 : 0,
              }}
            /> */}
        {/* </ListItemButton>
        </ListItem> */}

        {(isAdmin || isPartner) && (
          <ListItem
            key={"Zed-Pocket Money"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleZed}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <CreditCardIcon
                sx={{
                  color: currentPath === "/Outlet" || currentPath === "/CardBins" ? zedRed : zedBlue,
                }}
              /> */}
                <ZedPocketIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Zed-Pocket Money"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color:
                    currentPath === "/Outlet" ||
                      currentPath === "/CardBins" ||
                      currentPath === "/DeviceModels" ||
                      currentPath === "/EditDeviceModel" ||
                      currentPath === "/terminal/mngt" ||
                      currentPath === "/TermUsers"
                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
              {zedOpen ? (
                <ExpandLess
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              )}
            </ListItemButton>
            <Collapse in={zedOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {Group === 'Admin' ?
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/payit/payitadmin");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Dashboard"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/payit/payitadmin" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>: null}
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/TermUsers");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Terminal Users"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: currentPath === "/TermUsers" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/terminal/mngt");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Terminal Mgt"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: currentPath === "/terminal/mngt" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/DeviceModels");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Device Models"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/DeviceModels" ||
                          currentPath === "/EditDeviceModel"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/Outlet");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Outlets"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: currentPath === "/Outlet" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/CardBins");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Card Bins"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: currentPath === "/CardBins" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
        )}

        {isAdmin  && (
          <ListItem
            key={"Terminals & Printers"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleTerminal}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <PointOfSaleIcon
                sx={{
                  color:
                    currentPath === "/TerminalsAndPrinters" ||
                    currentPath === "/AddTerminal" ||
                    currentPath === "/EditTerminal" ||
                    currentPath === "/AssignTerminal"
                      ? zedRed
                      : zedBlue,
                }}
              /> */}
                <TerminalsIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Terminals & Printers"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color:
                    currentPath === "/TerminalsAndPrinters" ||
                      currentPath === "/AddTerminal" ||
                      currentPath === "/EditTerminal" ||
                      currentPath === "/AssignTerminal"
                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
              {terminalOpen ? (
                <ExpandLess
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              )}
            </ListItemButton>
            <Collapse in={terminalOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => navigate("/TerminalsAndPrinters")}
                >
                  <ListItemText
                    disableTypography
                    primary={"Terminals"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/TerminalsAndPrinters" ||
                          currentPath === "/AddTerminal" ||
                          currentPath === "/EditTerminal" ||
                          currentPath === "/AssignTerminal"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
        )}

        {isAdmin && (
          <ListItem key={"Billing"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ReceiptIcon sx={{ color: "#032541" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Billing"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color: "#032541",
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
       {(isPartner) && (
          <ListItem key={"Product & Services"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleProducts}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
              <InventoryIcon sx={{color:'#032541' , fontWeight:100}} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Product & Services"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  // color: "#032541",
                  color:
                    currentPath === "/partnerproductscategories" ||
                    currentPath === "/partnerproducts"
                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
              {productOpen ? (
                <ExpandLess
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              )}
            </ListItemButton>
            <Collapse in={productOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/partnerproductscategories");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Product Category"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/partnerproductscategories" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/partnerproducts");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Products"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/partnerproducts" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
        )}
        {(isAdmin || isPartner) && (
          <ListItem key={"Reports"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleReports}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <ArticleIcon
                sx={{
                  color:
                    currentPath === "/AllTransactions" ||
                    currentPath === "/VoidTransactions"
                      ? zedRed
                      : zedBlue,
                }}
              /> */}
                <ReportsIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Reports"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  // color: "#032541",
                  color:
                    currentPath === "/AllTransactions" ||
                      currentPath === "/VoidTransactions" ||
                      currentPath === "/admin/allzpmtransactions" ||
                      currentPath === "/admin/zpmbiotransactions" ||
                      currentPath === "/zpmactivationfeereport" ||
                      currentPath === "/admin/zpmcardtransactions" ||
                      currentPath === "/admin/zpmcashtransactionsadmin" ||
                      currentPath === "/admin/adminwalletereports" ||
                      currentPath === "/admin/adminreconciliationreport" ||
                      currentPath === "/failedcardreportadmin" ||
                      currentPath === "/partner/terminalsummaryreports" ||
                      currentPath === '/partner/zpmbankpaybillreport' ||
                      currentPath === "/zedb2btransactions" ||
                      currentPath === "/OverallPerformance"
                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
              {reportOpen ? (
                <ExpandLess
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              )}
            </ListItemButton>
            <Collapse in={reportOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              {isPartner || Group === 'Admin' ?   <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/OverallPerformance");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Overall Performance"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/OverallPerformance" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton> : null}
                {isPartner ? null :<>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/AllTransactions");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"All Transactions"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/AllTransactions" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/VoidTransactions");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Void Transactions"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/VoidTransactions" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
               </>}
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/admin/allzpmtransactions");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"All ZPM Transactions"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/admin/allzpmtransactions"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/admin/zpmbiotransactions");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"ZPM Bio Transactions"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/admin/zpmbiotransactions"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/admin/zpmcardtransactions");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"ZPM Card Transactions"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/admin/zpmcardtransactions"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                
                {Group === 'Admin' ? 
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/zpmactivationfeereport");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"ZPM Activation Transactions "}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/zpmactivationfeereport"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>:null}
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/failedcardreportadmin");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Failed Card Transactions "}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/failedcardreportadmin"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>


                {Group === 'Admin' ? 
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/admin/adminreconciliationreport");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"ZPM Reconciliation "}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/admin/adminreconciliationreport"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>:null}

                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/admin/zpmcashtransactionsadmin");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"ZPM Cash Reports "}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/admin/zpmcashtransactionsadmin"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>

                {Group === 'Admin' ? 
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/admin/adminwalletereports");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Student Wallet Balance "}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/admin/adminwalletereports"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>:null}

                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/zpmbioregistrationreport");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"ZPM Bio Registration Reports "}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/zpmbioregistrationreport"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/zedb2btransactions");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Zed B2B Transactions"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/zedb2btransactions"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                {Group  === 'Partner' ? 
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/partner/terminalsummaryreports");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Terminal Summary Reports "}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/partner/terminalsummaryreports"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>:null}
                {Group  === 'Partner' ? 
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/partner/zpmbankpaybillreport");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"ZPM BankPaybill Reports "}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/partner/zpmbankpaybillreport"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>:null}
              </List>
            </Collapse>
          </ListItem>
        )}
        {isAdmin && (
          <ListItem
            key={"Configurations"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleConfigurations}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <SettingsIcon
                sx={{
                color: currentPath === "/partners" || currentPath === "/GlobalConfigurations" || currentPath === "/ReasonCodes" || currentPath === "/BusinessCategories" ? zedRed : zedBlue,
                }}
              /> */}
                <ConfigurationsIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={"Configurations"}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color:
                    currentPath === "/partners" ||
                      currentPath === "/GlobalConfigurations" ||
                      currentPath === "/ReasonCodes" ||
                      currentPath === "/BusinessCategories" ||
                      currentPath === "/payItMode"
                      ? zedRed
                      : zedBlue,
                  opacity: open ? 1 : 0,
                }}
              />
              {configurationOpen ? (
                <ExpandLess
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    display: open ? "inherit" : "none",
                  }}
                />
              )}
            </ListItemButton>
            <Collapse in={configurationOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => navigate("/partners")}
                >
                  <ListItemText
                    disableTypography
                    primary={"Partners"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: currentPath === "/partners" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/GlobalConfigurations");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Global Configs"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/GlobalConfigurations"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
               
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/ReasonCodes");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Reason Codes"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: currentPath === "/ReasonCodes" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/BusinessCategories");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Business Categories"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/BusinessCategories" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/payItMode");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"ZPM Modes"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: currentPath === "/payItMode" ? zedRed : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/admin/auditlogs");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Audit Logs"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/admin/auditlogs"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/partners/insurancecompanies");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Insurance Companies"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/partners/insurancecompanies"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/partners/vehicleroutes");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Vehicle Routes"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/partners/vehicleroutes"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/partners/vehicletypes");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Vehicle Types"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/partners/vehicletypes"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => {
                    navigate("/admin/zedmpesadarajasetup");
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={"Zed Mpesa Daraja"}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color:
                        currentPath === "/admin/zedmpesadarajasetup"
                          ? zedRed
                          : zedBlue,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
        )}

        <ListItem key={"About Zed"} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {/* <InfoIcon sx={{ color: "#032541" }} /> */}
              <AboutIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={"About Zed"}
              sx={{
                fontFamily: "Poppins",
                fontSize: 12,
                fontWeight: "normal",
                fontStyle: "normal",
                textAlign: "left",
                color: "#032541",
                opacity: open ? 1 : 0,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
}
