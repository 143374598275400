import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import SetWorkFlow from "../Rental/Utility/updateSetupWorkFlow";
import MpesaIcon from "../School/Images/mpesaImage.svg"
import BankPaybill from "../School/Images/bank-paybill.svg"

import CardIcon from "../School/Images/cardIcon.svg"
import BankIcon from "../School/Images/bankImage.svg"
import cashicon from "../School/Images/cashicon.svg"
import SettleInvoiceIcon from "../../images/settle-invoice-icn-2.svg"
import MoMoIcon from "../School/Images/MoMoIcon.svg"
import GoodsIcon from "../School/Images/goods.svg"
import PaypalIcon from '../School/Images/paypal-icn.svg'
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from "@mui/material/Switch";
import DarajaSetUp from "../School/DarajaSetUp";
import BankSetup from "../School/BankSetup";
import HttpComponent from "../School/MakeRequest";
import { clearAdditions } from "../../features/customerSlice";
import { changeCategory } from '../../features/generalBusinessCategorySlice'
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useNavigate } from 'react-router-dom';
import BankPayBill from '../School/bankPaybillSetup';
import CardSetup from '../School/cardSetup';
import MTNMomoSetup from '../School/MTNMomoSetup';
import { handleIsBankPaybillSetup, handleIsBankSetup, handleIsCardSetup, handleIsCashSetup, handleIsMomoSetup, handleIsMpesaSetup, resetPaymentOptions, setBankPaybillStatus, setBankStatus, setCardStatus, setCashStatus, setGSStatus, setMpesaStatus, setPaypalStatus } from '../../features/paymentOptionsSlice';
import PaypalSetup from '../School/PaypalSetup';
import BankOptions from '../School/Banks/BankOptions';
import { handleSchoolTypeName, removeSchoolTypeName } from "../../features/schoolType";
import BankPocketmoney from '../School/Banks/BankPocketmoney';
import AuthecateUser from './autheticatedComponent';
import ectizenPng from '../School/Images/ecitizen.png'
import EcitizenSetUp from '../School/ecitizenSetUp';
import AirteImg from "../../common/images/airtel.png"
import { AirtelMoney } from '../School/airtelMoney';
import { GetDefaultCurrency } from '../../common/currencyconverter';

const PaymentSettingUp = ({ next }) => {
    const [successToast, setSuccessToast] = useState({ state: false, message: "" })
    const [showDarajaSetup, setShowDarajaSetup] = useState(false)
    const [showBankSetup, setShowBankSetup] = useState(false)
    const [showCardSetup, setShowCardSetup] = useState(false)
    const [showPaypalSetup, setShowPaypalSetup] = useState(false)
    const [showEcitizenSetup, setShowEcitizenSetup] = useState(false)
    const [defaultCurrency , setDefaultCurrency] = useState(GetDefaultCurrency())
    //   const [showGsSetup, setShowGsSetup] = useState(false)
    const [showBankPaybillSetup, setShowBankPaybillSetup] = useState(false)
    const [showCashSetup, setShowCashSetup] = useState(false)
    const [showMTNMomoSetup, setShowMTNMomoSetup] = useState(false)
    const [mpesaChecked, setMpesaChecked] = useState(false);
    const [cashChecked, setCashChecked] = useState(false);
    const[isSettleInvoiceChecked, setIsSettleInvoiceChecked] = useState(false)
    const [bankChecked, setBankChecked] = useState(false);
    const [momoChecked, setMomoChecked] = useState(false);
    const [airtelMoneyChecked, setAirtelMoneyChecked] = useState(false);
    const [cardChecked, setCardChecked] = useState(false);
    const [paypalChecked, setPaypalChecked] = useState(false);
    const [isPocketMoneyChecked, setIsPocketMoneyChecked] = useState(false)
    const [isPocketMoneyEnabled, setIsPocketMoneyEnabled] = useState(false)
    const [gsChecked, setGSChecked] = useState(false);
    const [bankPaybillChecked, setBankPaybillChecked] = useState(false);
    const [cardData, setCardData] = useState([]);
    const [countryData, setCountryData] = useState({});
    const [isKenya, setIsKenya] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [pageStatus, setPageStatus] = useState("initial")
    const [bankbills, setBankbills] = useState([])
    const [fromBackend, setFromBackend] = useState(false);
    const [isCashActivated, setIsCashActivated] = useState()
    const [isSettleInvoiceActivated , setSettleInvoiceActivated] = useState(false)
    const [cardPocketMoneyStatus, setCardPocketMoney] = useState()
    const [cardPocketMoneyBioStatus, setCardOPocketMoneyBioStatus] = useState()
    //Auth
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const businessCategory = localStorage.getItem('businessCategory');
    const { isBankPaybillSetup, isBankSetup, isMpesaSetup, isCardSetup, isCashSetup, isMomoSetup, cardStatus, mpesaStatus, KCBMobileStatus, paypalStatus, gsStatus, bankStatus, bankPaybillStatus } = useSelector((store) => store.payment);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem('X-Authorization');
    const [ectizenStatus, setEctizenStatus] = useState(false)
    const [ectizenChecked, setEctizenChecked] = useState(false)
    const [ecitizenSetup, setEctizenSetup] = useState(false)
    const [airtelMoneyStatus ,setAirtelMoneyStatus] = useState(false)

    const completeStyle = {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
        "backgroundColor": "#17ae7b",
        color: "white",
        '&:hover': {
            backgroundColor: '#17ae7b',
            color: 'white'
        }
    }

    //   changing switch theme when active
    const PinkSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: pink[600],
            '&:hover': {
                backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: pink[600],
        },
    }));

    const CustomSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 45,
        height: 23,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 18,
            height: 18,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })


    //   activating card
    const handleCard = () => {
        //   setCardChecked(!cardChecked)
        let state;
        if (!isCardSetup) {
            state = false;
        } else if (isCardSetup) {
            state = true;
        }
        HttpComponent({
            method: 'POST',
            url: `/api/activate_card?status=${state}`,
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data?.status === 201) {
                setSuccessShow({
                    state: true,
                    message: state === true ? "Card Added Successfully" : "Card Removed Successfully"
                })
                //   setCardData(data);
                console.log(cardData);
                dispatch(handleIsCardSetup(cardStatus));
                console.log(state);
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    //   activating mpesa
    const handleMpesa = () => {
        //   setCardChecked(!cardChecked)
        let state;
        if (!isMpesaSetup) {
            state = false;
        } else if (isMpesaSetup) {
            state = true;
        }
        HttpComponent({
            method: 'POST',
            url: `/api/mpesa_portal_setup?status=${state}`,
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data?.status === 201) {
                setSuccessShow({
                    state: true,
                    message: state === true ? "Mpesa Added Successfully" : "Mpesa Removed Successfully"
                })
                //   setCardData(data);
                //   console.log(cardData);
                dispatch(handleIsMpesaSetup(mpesaStatus));
                console.log(state);
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }


    //   activating bankpaybill
    const handleBankPaybill = () => {
        //   setCardChecked(!cardChecked)
        let state;
        if (!isBankPaybillSetup) {
            state = false;
        } else if (isBankPaybillSetup) {
            state = true;
        }
        HttpComponent({
            method: 'POST',
            url: `/api/v1/payments/activate_kcb_mpesa?status=${state}`,
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data?.status === 201) {
                setSuccessShow({
                    state: true,
                    message: state === true ? "Paybill Added Successfully" : "Paybill Removed Successfully"
                })
                //   setCardData(data);
                //   console.log(cardData);
                dispatch(handleIsBankPaybillSetup(bankPaybillStatus));
                console.log(state);
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    //activate cash

    function handleActivateCash() {

        try {
            HttpComponent({
                method: "POST",
                url: `/api/activate_cash_payment?status=${isCashActivated === true ? false : true}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'cash setup')
                if (data?.status === 201) {
                    dispatch(handleIsCashSetup(true))
                }
                checkStatus()
            })
        } catch (error) {

        }
    }
    function handleActivateSettleInvoice() {

        try {
            HttpComponent({
                method: "POST",
                url: `/api/v1/business/enablesetttleinvoicetstatus`,
                body:{
                    status: isSettleInvoiceActivated === true ? false : true
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'cash setup')
                if (data?.status === 200) {
                    //dispatch(handleIsCashSetup(true))
                    setSuccessShow({state:true , message:data?.response?.message})
                }
                checkStatus()
            })
        } catch (error) {

        }
    }

    console.log('paypal setup status', paypalStatus);
    console.log('bank status', bankStatus);

    const checkStatus = () => {
        console.log('here check status');
        dispatch(resetPaymentOptions())

        //setIsCashActivated

        HttpComponent({
            method: 'GET',
            url: `/api/get_payment_methods_with_status`,
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here is payment status data", data);
            if (data?.status === 201) {
                console.log('we here', data.response.data.find(method => method.name === "Banks")?.status);
                const mpesaStatus = data.response.data.find(method => method.name === "Mpesa")?.status;
                const cardStatus = data.response.data.find(method => method.name === "Card")?.status;
                const paybillStatus = data.response.data.find(method => method.name === "Bank Paybill")?.status;
                const bankStatus = data.response.data.find(method => method.name === "Banks")?.status;
                const cashStatus = data.response.data.find(method => method.name === "Cash")?.status;
                const paypalStatus = data.response.data.find(method => method.name === "PayPal")?.status;
                const isCashOn = data.response.data.find(method => method.name === "Cash")?.status;
                const gsStatus = data.response.data.find(method => method.name === "Goods")?.status;
                const isAirtelOn = data.response.data.find(method => method.name === "Airtel")?.status;
                const cardPocketMoney = data?.response?.data?.find(method => method.name === "Banks")?.paymentOptions?.find(method => method?.name === "KCB")?.kcb?.find(method => method.name === "KCB CARD POCKET MONEY")?.status
                const bioPocketMoney = data?.response?.data?.find(method => method.name === "Banks")?.paymentOptions?.find(method => method?.name === "KCB")?.kcb?.find(method => method.name === "KCB POCKET MONEY")?.status
                const settleInvoiceStatus = data?.response?.data?.find(method => method?.name === "settleInvoiceStatus")?.status;

                setCardPocketMoney(cardPocketMoney)
                setCardOPocketMoneyBioStatus(bioPocketMoney)
                if (cardPocketMoney) {
                    setIsPocketMoneyEnabled(true)
                }

                if (bioPocketMoney) {
                    setIsPocketMoneyEnabled(true)
                }


                setFromBackend(mpesaStatus)
                setMpesaChecked(mpesaStatus)
                setIsCashActivated(isCashOn)
                setSettleInvoiceActivated(settleInvoiceStatus)
                setAirtelMoneyStatus(isAirtelOn)
                // setBankStatus(bankStatus)



                dispatch(setCardStatus(cardStatus))
                dispatch(setMpesaStatus(mpesaStatus));
                dispatch(setBankPaybillStatus(paybillStatus));
                dispatch(setBankStatus(bankStatus));
                dispatch(setCashStatus(cashStatus))
                dispatch(setPaypalStatus(paypalStatus))
                dispatch(setGSStatus(gsStatus));

            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        checkStatus()
    }, []);




    //   check the country code 
    const handleCountryCheck = () => {
        HttpComponent({
            method: 'GET',
            url: `/api/get_currencyByCountry?country=kenya`,
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data?.status === 201) {
                setCountryData({ ...countryData, data });
            } else {
            }
            console.log(countryData);
        }).catch((error) => {
            console.error(error, 'error');
        })
    }
    const checkEcitizenStatusMethod = async () => {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/ecitizen/get_status`,
            body: null,
            token: X_Authorization
        }).then((data) => {

            if (data?.status === 200) {
                let status = data.response?.ecitizenStatus
                console.log(status, 'status---')
                setEctizenStatus(status)

            } else {
            }
            console.log(countryData);
        }).catch((error) => {
            console.error(error, 'error');
        })


    }


    const getBankPayBills = async (props) => {
        try {
            const bankres = await fetch(`${baseUrl}/api/v1/payments/get_bank_list`, {
                method: "GET",
                headers: {
                    Content_Type: "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            });
            const data = await bankres.json();

            if (data?.Status === "SUCCESS") {
                setBankbills(data.data)
                // dispatch(handleIsBankPaybillSetup(true));
                console.log(data.data, 'bankres.response.data')
            }
        } catch (err) {
            console.log(err);

        }
    }

    useEffect(() => {
        getBankPayBills()

    }, [])

    useEffect(() => {
        if (showDarajaSetup) {
            setShowBankSetup(false)
        }
        if (showBankSetup) {
            setShowDarajaSetup(false)
        }
    }, [showBankSetup, showDarajaSetup]);

    useEffect(() => {
        if (mpesaChecked) {
            setShowDarajaSetup(true)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowCardSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            //   setShowGsSetup(false)
        } else {
            setShowDarajaSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowCardSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            //   setShowGsSetup(false)
        }
    }, [mpesaChecked]);

    //   bank option rendering
    useEffect(() => {
        if (bankChecked) {
            setShowBankSetup(true)
            setShowDarajaSetup(false)
            setShowBankPaybillSetup(false)
            setShowCardSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            //   setShowGsSetup(false)
        } else {
            setShowBankSetup(false)
            //   setShowBankSetup(true)
            setShowDarajaSetup(false)
            setShowBankPaybillSetup(false)
            setShowCardSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            //   setShowGsSetup(false)
        }
    }, [bankChecked]);

    //   bank paybill option rendering
    useEffect(() => {
        if (bankPaybillChecked) {
            setShowBankPaybillSetup(true)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowCardSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            //   setShowGsSetup(false)
        } else {
            setShowBankPaybillSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowCardSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            // setShowGsSetup(false)
        }
    }, [bankPaybillChecked]);

    //   card option rendering
    useEffect(() => {
        if (cardChecked) {
            setShowCardSetup(true)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            //   setShowGsSetup(false)
        } else {
            setShowCardSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            //   setShowGsSetup(false)
        }
    }, [cardChecked]);

    //   MTN MoMo option rendering
    useEffect(() => {
        if (momoChecked) {
            setShowMTNMomoSetup(true)
            setShowCardSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            // setShowGsSetup(false)
        } else {
            setShowMTNMomoSetup(false)
            setShowCardSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            // setShowGsSetup(false)
        }
    }, [momoChecked]);

    // paypal option rendering
    useEffect(() => {
        if (paypalChecked) {
            setShowPaypalSetup(true)
            setShowMTNMomoSetup(false)
            setShowCardSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setIsPocketMoneyChecked(false)
            // setShowGsSetup(false)
        } else {
            setShowMTNMomoSetup(false)
            setShowCardSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            // setShowGsSetup(false)
        }
    }, [paypalChecked]);

    // ecitizen option rendering
    useEffect(() => {
        if (ectizenChecked) {
            setShowEcitizenSetup(true)
            setShowMTNMomoSetup(false)
            setShowCardSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowPaypalSetup(false)
            setIsPocketMoneyChecked(false)
            // setShowGsSetup(false)
        } else {
            setShowMTNMomoSetup(false)
            setShowCardSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowPaypalSetup(false)
            setShowEcitizenSetup(false)
            setIsPocketMoneyChecked(false)
            // setShowGsSetup(false)
        }
    }, [ectizenChecked]);

    useEffect(() => {
        if (isPocketMoneyChecked) {
            setIsPocketMoneyChecked(true)
            setShowMTNMomoSetup(false)
            setShowCardSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowPaypalSetup(false)
        } else {
            setIsPocketMoneyChecked(false)
            setShowMTNMomoSetup(false)
            setShowCardSetup(false)
            setShowDarajaSetup(false)
            setShowBankSetup(false)
            setShowBankPaybillSetup(false)
            setShowPaypalSetup(false)
        }
    }, [isPocketMoneyChecked])

    // check country trigger
    useEffect(() => {
        handleCountryCheck();

    }, [])

    useEffect(() => {
        checkEcitizenStatusMethod()
    }, [showEcitizenSetup])

    //   save business details 
    const completeBusinessSetup = () => {
        if (businessCategory === 'ZED_ECOMMERCE') {
            completeZedEcommerce()
        } else {
            SetWorkFlow({ flowName: 'PARENT', token: X_Authorization })
            localStorage.setItem("workflow", "PARENT")
            localStorage.setItem("group", "Merchant")
            dispatch(changeCategory('eatery'));
            navigate("/dashboard")
            setSuccessToast({ state: true, message: "Congratulations! Business was created successfully." })
        }
    }


    const navigate = useNavigate();
    const dispatch = useDispatch();
    var currentURL = window.location.href;
    console.log(currentURL, 'currentURL');
    const [isAuthecated, setIsAuthecated] = useState(true);
    const handleIsAuthecated = (childData) => {
        let state = childData.state;
        setIsAuthecated(state)
    }

    const completeZedEcommerce = () => {
        SetWorkFlow({ flowName: 'ZED_ECOMMERCE', token: X_Authorization })
        localStorage.setItem("workflow", "ZED_ECOMMERCE")
        localStorage.setItem("group", "Merchant")
        dispatch(changeCategory('ZED_ECOMMERCE'));
        navigate("/dashboard")
        setSuccessToast({ state: true, message: "Congratulations! Business was created successfully." })
    }





    return (
        <Box component="div" sx={{ display: "flex", width: "100%", marginTop: "3%" }}>
            {!isAuthecated && currentURL?.includes('paymentsettingup') ? <>
                <AuthecateUser handleIsAuthecated={handleIsAuthecated} />
            </> :
                <>  <>
                    <Box component="div" sx={{ width: "50%" }}>

                        <SuccessAlert
                            vertical="top"
                            horizontal="right"
                            onClose={() => setSuccessToast({ ...successToast, state: false })}
                            open={successToast.state}
                            message={successToast.message} />
                        <SuccessAlert
                            vertical="top"
                            horizontal="right"
                            onClose={() => setSuccessShow({ ...successShow, state: false })}
                            open={successShow.state}
                            message={successShow.message} />

                        <ErrorAlert
                            vertical="top"
                            horizontal="right"
                            onClose={() => setErrorShow({ ...errorShow, state: false })}
                            open={errorShow.state}
                            message={errorShow.message} />
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <Typography sx={{ color: "#707070", margin: 1 }}>
                                Add at least <span style={{ color: '#dc3545', fontWeight: 700 }}>1</span> payment method.
                            </Typography>
                            {(businessCategory !== 'School' && businessCategory !== 'Rental') && <Button sx={completeStyle} onClick={completeBusinessSetup}>
                                Complete
                            </Button>}
                        </Box>
                        {/*mobile money start*/}
                        {
                        defaultCurrency !== "NGN" &&    <Box component="div" sx={{
                            width: "100%",
                            display: "flex",
                            flexDirect32qeadszxion: "column",
                            justifyContent: "center",
                            border: "solid 1px rgba(112, 112, 112, 0.15)",
                            borderRadius: "6px",
                            margin: 1
                        }}
                        >
                            <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                                setMpesaChecked(!mpesaChecked);
                            }}>

                                <Box component="div" sx={{ display: "flex" }}>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <img src={MpesaIcon} alt="" />
                                    </Box>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                        <Typography sx={{ color: "#032541" }}>
                                            Mpesa
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                    {mpesaStatus  === false  ? <Typography style={{ color: "#707070", marginRight: '5px' }}>Not Set</Typography> : mpesaStatus === true ? <Typography style={{ color: '#33cf4d', marginRight: '5px' }}>Active</Typography> : <Typography style={{ color: "#ff8503", marginRight: '5px' }}>Pending</Typography>}
                                    <CustomSwitch
                                        checked={mpesaStatus}
                                        onChange={() => setMpesaChecked(!mpesaChecked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="warning"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        }
                     
                        {/*    mpesa end*/}

                        {/* bank paybill setup */}
                        {/* <Box component="div" sx={{
            width:"100%",
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            border:"solid 1px rgba(112, 112, 112, 0.15)",
            borderRadius:"6px",
            margin:1
        }}>
            <Box component="div" sx={{width:"100%", display:"flex", justifyContent:"space-between", padding:0.5, cursor:'pointer'}} onClick={() =>{
							setBankPaybillChecked(!bankPaybillChecked);
						}}>

                <Box component="div" sx={{display:"flex"}}>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                        <img src={BankPaybill} alt=""/>
                    </Box>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginLeft:1}}>
                        <Typography sx={{color:"#032541"}}>
                            Bank  Paybill
                        </Typography>
                    </Box>
                </Box>

                <Box component="div" sx={{display:"flex", alignItems: 'center', justifyContent:"center"}}>
                {bankPaybillStatus === false ? <Typography style={{ color: "#707070", marginRight:'5px' }}>Not Set</Typography> : bankPaybillStatus === true ? <Typography style={{ color:'#33cf4d', marginRight:'5px' }}>Active</Typography> : <Typography  style={{ color: "#ff8503", marginRight:'5px' }}>Pending</Typography>}
                    <CustomSwitch
                        // disabled
                        checked={bankPaybillStatus}
                        onChange={()=>setBankPaybillChecked(!bankPaybillChecked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="warning"
                    />
                </Box>
            </Box>
        </Box> */}
                        {/* bank paybill setup ends */}


                        {/* bank setup*/}
                        <Box component="div" sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                border: "solid 1px rgba(112, 112, 112, 0.15)",
                                borderRadius: "6px",
                                margin: 1
                            }}>
                                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                                    setBankChecked(!bankChecked);
                                }}>
    
                                    <Box component="div" sx={{ display: "flex" }}>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <img src={BankIcon} alt="" />
                                        </Box>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                            <Typography sx={{ color: "#032541" }}>
                                                Bank
                                            </Typography>
                                        </Box>
                                    </Box>
    
                                    <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                        {!bankStatus ? <Typography style={{ color: "#707070", marginRight: '5px' }}>Not Set</Typography> : (bankStatus) ? <Typography style={{ color: '#33cf4d', marginRight: '5px' }}>Active</Typography> : <Typography style={{ color: "#ff8503", marginRight: '5px' }}>Pending</Typography>}
                                        <CustomSwitch
                                            // disabled
                                            checked={bankStatus}
                                            onChange={() => setBankChecked(!bankChecked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="warning"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        
                 
                        {/*end bank setup*/}

                        {/*    card setup */}
                        {
                            (defaultCurrency !== "NGN") &&     <Box component="div" sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                border: "solid 1px rgba(112, 112, 112, 0.15)",
                                borderRadius: "6px",
                                margin: 1
                            }}>
                                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                                    setCardChecked(!cardChecked);
                                }}>
    
                                    <Box component="div" sx={{ display: "flex" }}>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <img src={CardIcon} alt="" />
                                        </Box>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                            <Typography sx={{ color: "#032541" }}>
                                                Card
                                            </Typography>
                                        </Box>
                                    </Box>
    
                                    <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                        {cardStatus === false ? <Typography style={{ color: "#707070" }}>Not Set</Typography> : cardStatus === true ? <Typography style={{ color: '#33cf4d', marginRight: '5px' }}>Active</Typography> : <Typography style={{ color: "#ff8503" }}>Pending</Typography>}
                                        <CustomSwitch
                                            // disabled
                                            checked={cardStatus}
                                            onChange={() => setCardChecked(!cardChecked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="warning"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        }
                    
                        {/*    card setup ends*/}

                        {/* cash setup*/}
                        <Box component="div" sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            border: "solid 1px rgba(112, 112, 112, 0.15)",
                            borderRadius: "6px",
                            margin: 1
                        }}>
                            <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                                setCashChecked(!cashChecked);
                            }}>

                                <Box component="div" sx={{ display: "flex" }}>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <img src={cashicon} alt="" />
                                    </Box>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                        <Typography sx={{ color: "#032541" }}>
                                            Cash
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                    {!isCashActivated ? <Typography style={{ color: "#707070" }}>Not Set</Typography> : (isCashActivated) ? <Typography style={{ color: '#33cf4d', marginRight: '5px' }}>Active</Typography> : null}
                                    <CustomSwitch
                                        // disabled
                                        checked={isCashActivated}
                                        onClick={handleActivateCash}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="warning"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {/* end of cash setup */}

                        {/* momo setup */}
                        {
                            defaultCurrency === "UGX" &&     <Box component="div" sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                border: "solid 1px rgba(112, 112, 112, 0.15)",
                                borderRadius: "6px",
                                margin: 1
                            }}>
                                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                                    setMomoChecked(!momoChecked);
                                }}>
    
                                    <Box component="div" sx={{ display: "flex" }}>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <img src={MoMoIcon} alt="momo-icon" />
                                        </Box>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                            <Typography sx={{ color: "#032541" }}>
                                                MTN MoMo
                                            </Typography>
                                        </Box>
                                    </Box>
    
                                    <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                        {!momoChecked && !isMomoSetup ? <Typography style={{ color: "#707070" }}>Not Set</Typography> : (!momoChecked && isMomoSetup) || (momoChecked && isMomoSetup) ? <Typography style={{ color: "#ff8503" }}>Active</Typography> : <Typography style={{ color: "#ff8503" }}>Pending</Typography>}
                                        <CustomSwitch
                                            // disabled
                                            checked={isMomoSetup}
                                            onChange={() => setMomoChecked(!momoChecked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="warning"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        }
                    
                        {/* end of momo setup */}

                         {/* Airtel Money setup */}
                         {(defaultCurrency === "UGX") ||( defaultCurrency === "KSH") && 
                                 <Box component="div" sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                border: "solid 1px rgba(112, 112, 112, 0.15)",
                                borderRadius: "6px",
                                margin: 1
                            }}>
                                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                                    setAirtelMoneyChecked(!airtelMoneyChecked);
                                }}>
    
                                    <Box component="div" sx={{ display: "flex" }}>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <img src={AirteImg} style={{width:"50px" , height:'50px' , objectFit:'contain'}} alt="airtel-icon" />
                                        </Box>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                            <Typography sx={{ color: "#032541" }}>
                                                Airtel Money
                                            </Typography>
                                        </Box>
                                    </Box>
    
                                    <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                        {!airtelMoneyChecked && !airtelMoneyStatus ? <Typography style={{ color: "#707070" }}>Not Set</Typography> : (!airtelMoneyChecked && airtelMoneyStatus) || (airtelMoneyChecked && airtelMoneyStatus) ? <Typography style={{ color: "#ff8503" }}>Active</Typography> : <Typography style={{ color: "#ff8503" }}>Pending</Typography>}
                                        <CustomSwitch
                                            // disabled
                                            checked={airtelMoneyStatus}
                                            onChange={() => setAirtelMoneyChecked(!airtelMoneyChecked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="warning"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                         }
                     
                        {/* end of airtel moeny setup */}

                        {/* Ecitizen  setup */}
                        {
                             defaultCurrency === "KES" &&      <Box component="div" sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                border: "solid 1px rgba(112, 112, 112, 0.15)",
                                borderRadius: "6px",
                                margin: 1
                            }}>
                                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                                    setEctizenChecked(!ectizenChecked);
                                }}>
    
                                    <Box component="div" sx={{ display: "flex" }}>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <img src={ectizenPng} alt="Ecitizen" style={{ width: "46.4px", height: "46.4px", objectFit: "contain" }} />
                                        </Box>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                            <Typography sx={{ color: "#032541" }}>
                                                Ecitizen
                                            </Typography>
                                        </Box>
                                    </Box>
    
                                    <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                        {ectizenStatus === false ? <Typography style={{ color: "#707070" }}>Not Set</Typography> : ectizenStatus === true ? <Typography style={{ color: '#33cf4d', marginRight: '5px' }}>Active</Typography> : <Typography style={{ color: "#ff8503" }}>Pending</Typography>}
                                        <CustomSwitch
                                            // disabled
                                            checked={ectizenStatus}
                                            onChange={() => setEctizenChecked(!ectizenChecked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="warning"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        }
                   
                   {
                      <Box component="div" sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        border: "solid 1px rgba(112, 112, 112, 0.15)",
                        borderRadius: "6px",
                        margin: 1
                    }}>
                        <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                            setPaypalChecked(!paypalChecked);
                        }}>

                            <Box component="div" sx={{ display: "flex" }}>
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <img src={PaypalIcon} alt="paypal" />
                                </Box>
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                    <Typography sx={{ color: "#032541" }}>
                                        Paypal
                                    </Typography>
                                </Box>
                            </Box>

                            <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                {paypalStatus === false ? <Typography style={{ color: "#707070" }}>Not Set</Typography> : paypalStatus === true ? <Typography style={{ color: '#33cf4d', marginRight: '5px' }}>Active</Typography> : <Typography style={{ color: "#ff8503" }}>Pending</Typography>}
                                <CustomSwitch
                                    // disabled
                                    checked={paypalChecked}
                                    onChange={() => setPaypalChecked(!paypalChecked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="warning"
                                />
                            </Box>
                        </Box>
                    </Box>
                   }
                     
                        {/* end of paypal setup */}
                        {/* Settle invoice setup*/}
                        {
                              <Box component="div" sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                border: "solid 1px rgba(112, 112, 112, 0.15)",
                                borderRadius: "6px",
                                margin: 1
                            }}>
                                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                                    setIsSettleInvoiceChecked(!isSettleInvoiceChecked);
                                }}>
    
                                    <Box component="div" sx={{ display: "flex" }}>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <img src={SettleInvoiceIcon} alt="" />
                                        </Box>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                            <Typography sx={{ color: "#032541" }}>
                                                Settle Invoice
                                            </Typography>
                                        </Box>
                                    </Box>
    
                                    <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                        {!isSettleInvoiceActivated ? <Typography style={{ color: "#707070" }}>Not Set</Typography> : (isSettleInvoiceActivated) ? <Typography style={{ color: '#33cf4d', marginRight: '5px' }}>Active</Typography> : null}
                                        <CustomSwitch
                                            // disabled
                                            checked={isSettleInvoiceActivated}
                                            onClick={handleActivateSettleInvoice}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="warning"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        }
                     
                        { }

                        {/* Bank Account Pocket Money Set Up! */}
                        {/* {businessCategory === 'School' ?
                            <Box component="div" sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                border: "solid 1px rgba(112, 112, 112, 0.15)",
                                borderRadius: "6px",
                                margin: 1
                            }}>
                                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                                    setIsPocketMoneyChecked(!isPocketMoneyChecked);
                                }}>

                                    <Box component="div" sx={{ display: "flex" }}>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <img src={BankIcon} alt="paypal" />
                                        </Box>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 1 }}>
                                            <Typography sx={{ color: "#032541" }}>
                                                Pocket Money Setup
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                        {isPocketMoneyEnabled === false ? <Typography style={{ color: "#707070" }}>Not Set</Typography> : isPocketMoneyEnabled === true ? <Typography style={{ color: '#33cf4d', marginRight: '5px' }}>Active</Typography> : <Typography style={{ color: "#ff8503" }}>Pending</Typography>}
                                        <CustomSwitch
                                            // disabled
                                            checked={isPocketMoneyEnabled}
                                            onChange={() => setIsPocketMoneyChecked(!paypalChecked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="warning"
                                        />
                                    </Box>
                                </Box>
                            </Box> : null} */}

                        {/* goods and services setup */}
                        <Box component="div" sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            border: "solid 1px rgba(112, 112, 112, 0.15)",
                            borderRadius: "6px",
                            margin: 1
                        }}>
                            {/* <Box component="div" sx={{width:"100%", display:"flex", justifyContent:"space-between", padding:0.5, cursor:'pointer'}} onClick={() =>{
							setGSChecked(!gsChecked);
						}}>

                <Box component="div" sx={{display:"flex"}}>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                        <img src={GoodsIcon} alt="paypal"/>
                    </Box>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginLeft:1}}>
                        <Typography sx={{color:"#032541"}}>
                           Goods/Services
                        </Typography>
                    </Box>
                </Box>

                <Box component="div" sx={{display:"flex", alignItems: 'center', justifyContent:"center"}}>
                {gsStatus === false ? <Typography style={{ color: "#707070" }}>Not Set</Typography> : cardStatus === true ? <Typography style={{ color:'#33cf4d', marginRight:'5px' }}>Active</Typography> : <Typography style={{ color: "#ff8503" }}>Pending</Typography>}
                    <CustomSwitch
                        // disabled
                        checked={cardStatus}
                        onChange={() => setGSChecked(!gsChecked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="warning"
                    />
                </Box>
            </Box> */}
                        </Box>

                        {/* end goods and services setup */}
                        {currentURL?.includes('paymentsettingup') ? null :


                            <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                {(businessCategory === 'School' || businessCategory === 'Rental') && <Button onClick={next}>
                                    Skip
                                </Button>}
                                {(businessCategory === 'School' || businessCategory === 'Rental') && <Button onClick={next} sx={completeStyle}>
                                    Next
                                </Button>}
                                {/* {(businessCategory === 'ZED_ECOMMERCE') && <Button onClick={next} sx={completeStyle}>
                                    Complete
                                </Button>} */}
                            </Box>
                        }
                    </Box>

                    <Box component="div" sx={{ width: "50%", margin: 1, display: "flex", justifyContent: "center" }}>
                        {showDarajaSetup && <DarajaSetUp setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} setShowDarajaSetup={setShowDarajaSetup} handleMpesa={handleMpesa} setMpesaStatus={setMpesaStatus} />}
                        {showBankSetup && <BankOptions setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} setShowBankSetup={setShowBankSetup} bankChecked={bankChecked} setBankChecked={setBankChecked} errorShow={errorShow} />}
                        {showBankPaybillSetup && <BankPayBill setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} setShowBankPaybillSetup={setShowBankPaybillSetup} showForm={showForm} pageStatus={pageStatus} setPageStatus={setPageStatus} bankbills={bankbills} setBankbills={setBankbills} handleBankPaybill={handleBankPaybill} />}
                        {showCardSetup && <CardSetup setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} setShowCardSetup={setShowCardSetup} cardData={cardData} handleCard={handleCard} setCardStatus={setCardStatus} />}
                        {showPaypalSetup && <PaypalSetup setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} setShowPaypalSetup={setShowPaypalSetup} />}
                        {showEcitizenSetup && <EcitizenSetUp setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} setShowEcitizenSetup={setShowEcitizenSetup} />}
                        {showCashSetup && <DarajaSetUp setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} setShowDarajaSetup={setShowDarajaSetup} />}
                        {showMTNMomoSetup && <MTNMomoSetup setSuccessShow={setSuccessShow} setMomoChecked={setMomoChecked} setErrorShow={setErrorShow} setShowMTNMomoSetup={setShowMTNMomoSetup} />}
                        {isPocketMoneyChecked && <BankPocketmoney cardPocketMoneyStatus={cardPocketMoneyStatus} cardPocketMoneyBioStatus={cardPocketMoneyBioStatus} />}
                        {airtelMoneyChecked && <AirtelMoney setShowAirtelMoneySetup={airtelMoneyChecked} setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} airtelMoneyStatus={airtelMoneyStatus}/>}
                    </Box>

                </></>}




        </Box>
    )
}

export default PaymentSettingUp;