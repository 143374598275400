import React, {useState, useEffect} from 'react';
import {Box, Button, TextField, Typography} from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import {useDispatch, useSelector} from "react-redux";
import {addCategoriesToAdd} from "../../features/businessCategorySlice";
import {SuccessAlert} from "../snackBar Alerts/successAlert";
import CustomSelectField from "../School/CustomSelectField";
import HttpComponent from './MakeRequest';

const ServiceCategoryForm = (props) =>{
    const [successToast, setSuccessToast] = useState({state: false, message: ""})
    const [successShow, setSuccessShow] = useState({state: false, message: ""})
    const businessCategory = localStorage.getItem('businessCategory')
    const [generalLedgerOptions, setGeneralLedgerOptions] = useState([])
    const ledgerOptions = generalLedgerOptions.map((option) => ({ value: option?.accountId, label: option?.name }))
    const cancelButtonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "padding":"12.5px 36.7px 12.5px 38.3px",
            "borderRadius":"4px",
            "border":"solid 1px #dc3545",
            "color":"#dc3545"
        }

    const buttonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        categoryName:"",
        categoryType:"",
        categoryDescription:"",
        accountId:""
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    function getGeneralLedgerCategories() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/business/get_general_ledger_accounts`,
                token: localStorage.getItem('X-Authorization'),
                body: null
            }).then((data) => {
                //console.log(data , 'dataaaaaaaaaaaaaaaa')
                if (data.status === 200) {
                    setGeneralLedgerOptions(data?.response?.data)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getGeneralLedgerCategories()
    }, [])

    const categoryTypeOptions = [
        {value:"Product", label:"Product"},
        {value:"Service", label:"Service"}
    ]

    const addToList = () =>{
        let newData ={
            name:formData.categoryName,
            description:formData.categoryDescription,
            type:formData.categoryType,
            productService:formData.categoryType,

            // type: businessCategory === 'School' ? 'Service' : 'Product',
            // productService: businessCategory === 'School' ? 'Service':'Product',
            //accountId:formData.accountId
        }
        dispatch(addCategoriesToAdd(newData))
        setFormData({
            categoryName: "",
            categoryType:"",
            categoryDescription:"",
            //accountId:''
        })
        setSuccessShow({state:true, message:"Category(s) created successfully."})
    }

    const saveHandler = () =>{
        addToList();
        setSuccessToast({state:true, message: "Category(s) created successfully."})
        props.setView("done")
    }

    const { categoriesToAdd} = useSelector((store) => store.businessCategory);
    console.log("formData", formData)

    return(
        <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
            <SuccessAlert
                vertical="top"
                horizontal="right"
                onClose={()=>setSuccessShow({...successShow, state:false})}
                open={successShow.state}
                message={successShow.message}
            />
            <SuccessAlert  vertical="top"
                               horizontal="right"
                               onClose={()=>setSuccessToast({...successToast, state:false})}
                               open={successToast.state}
                               message={successToast.message} />

                <Box style={{ width:'100%' }}>
                    <Box style={{ display:'flex' }}>
                        <Box>
                            <CustomInputLabel required={true} label={"Category Name"}/>
                            <CustomTextField value={formData.categoryName} onChange={handleInputChange} name={"categoryName"} placeholder={"Category Name"} sm />
                        </Box>
                        <Box style={{ marginLeft:'16px' }}>
                            <CustomInputLabel required={true} label={"Category Type"}/>
                            {/* {businessCategory === 'School' ?  <CustomTextField value={'Service'} onChange={handleInputChange} name={"categoryType"} placeholder={"Category Type"} sm />: <CustomTextField value={'Product'} onChange={handleInputChange} name={"categoryType"} placeholder={"Category Type"} sm /> } */}
                           
                            <CustomSelectField value={formData.categoryType} onChange={handleInputChange} name={"categoryType"} placeholder={"Category Type"} options={categoryTypeOptions} medium sm />
                        </Box>
                    </Box>
                    {/* <CustomInputLabel required={true} label={"GL Account"}/>
                    <CustomSelectField value={formData.accountId} onChange={handleInputChange} name={"accountId"} placeholder={"GL Account"} options={ledgerOptions} style={{width:'100%'}}/> */}
                    <CustomInputLabel required={true} label={"Category Description"}/>
                    <TextField
                    name={"categoryDescription"}
                    value={formData.categoryDescription}
                    onChange={handleInputChange}
                    placeholder={"Category Description"}
                    variant="outlined"
                    margin="dense"
                    sx={{marginBottom:2}}
                    multiline
                    minRows={3}
                    InputProps={{
                        sx: {
                            width: '548px',
                            fontSize:"0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                    />
                </Box>


            <Box component="div" sx={{width:"100%", display:"flex", justifyContent:"end"}}>
                {
                    categoriesToAdd === null ? (<Box component="div">
                    <Button sx={{
                            ...buttonStyles,
                            marginRight:1,
                            color:"red",
                            '&:hover': {
                                backgroundColor:"transparent",
                                color: 'red'
                            }
                        }}
                        onClick={props.next}
                        >
                            Skip
                        </Button>
    
                        <Button sx={{
                            ...buttonStyles,
                            border:"solid 1px rgba(0, 37, 67, 0.4)",
                            marginRight:1,
                            color:"rgba(0, 37, 67, 0.4)",
                            '&:hover': {
                                backgroundColor:"transparent",
                            }
                        }}
                        id="cancel-category"
                        onClick={()=> {
                            //add if for incase some categories has been added
                            console.log("ndjw")
                            if(!categoriesToAdd || categoriesToAdd?.length < 1){
                                props.setView("initial")
                            }else{
                                props.setView("done")
                            }
                        }}
                        >
                            Cancel
                        </Button>
    
                        <Button sx={{
                            ...buttonStyles,
                            border:"none",
                            borderRadius: '4px',
                            backgroundColor:'rgba(3, 37, 65, 0.4)',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor:'rgba(3, 37, 65, 0.4)',
                                color: '#fff'
                            }
                        }}
                        onClick={saveHandler}
                                disabled={!formData.categoryName.trim()}
                                id="preview-category"
                        >
                            Preview
                        </Button>
                    </Box>):
                    (<Box component="div">
                    <Button sx={{
                            ...buttonStyles,
                            marginRight:1,
                            color:"red",
                            '&:hover': {
                                backgroundColor:"transparent",
                                color: 'red'
                            }
                        }}
                        onClick={props.next}
                        >
                            Skip
                        </Button>
    
                        <Button sx={{
                            ...buttonStyles,
                            border:"solid 1px #032541",
                            marginRight:1,
                            color:"#032541",
                            '&:hover': {
                                backgroundColor:"transparent",
                            }
                        }}
                        onClick={()=> {
                            //add if for incase some categories has been added
                            console.log("ndjw")
                            if(!categoriesToAdd || categoriesToAdd?.length < 1){
                                props.setView("initial")
                            }else{
                                props.setView("done")
                            }
                        }}
                        >
                            Cancel
                        </Button>
    
                        <Button sx={{
                            ...buttonStyles,
                            border:"none",
                            borderRadius: '4px',
                            backgroundColor:"#032541",
                            color:"white",
                            '&:hover': {
                                backgroundColor:'#032541',
                                color: '#fff'
                            }
                        }}
                        onClick={saveHandler}
                        >
                            Preview ({categoriesToAdd.length})
                        </Button>
    
                    </Box>)
                }

            </Box>
        </Box>
    )
}

export default ServiceCategoryForm;
