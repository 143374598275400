import { Box, Typography ,Grid ,FormControl ,InputLabel ,Select,MenuItem ,Skeleton} from '@mui/material'
import CustomDataGrid from '../products/stockComponents/customDataGrid'
import { currencyconverter } from '../../common/currencyconverter'
import DateFormatter from '../../utils/dateFormatter'
import HttpComponent from '../School/MakeRequest'
import DirectionsCarFilledSharpIcon from '@mui/icons-material/DirectionsCarFilledSharp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useEffect, useMemo, useState } from 'react';
export default function TransportMerchantDashboard(props) {

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [cardData , setCardData] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [startDate ,setStartdate] = useState('')
    const group = localStorage.getItem('group')
    const [endDate ,setenddate] = useState('')

    const [defaultRecentStartDate ,setDefaultRecentStart] = useState(new Date())
    const [defaultRecentendDate ,setDefaultRecentend] = useState(new Date())

    // get recent transactions

    function getdashboardDetails() {
        setIsLoading(true)
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/dashboard_details?startDate=${startDate}&endDate=${endDate}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setCardData(data?.response?.data)
                    setIsLoading(false)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        getdashboardDetails();
    },[startDate ,endDate])


    


    // get recent transactions

    function transactionbyvehciledrilldown() {
        setPageState({ ...pageState, isLoading: true });
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getFareTransactionsByVehicle?page=${pageState.page}&limit=${dataGridPageSize}&startDate=${defaultRecentStartDate}&endDate=${defaultRecentendDate}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count });
                } else {
                    setPageState({ ...pageState, isLoading: false });
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        transactionbyvehciledrilldown();
    }, [pageState.page, dataGridPageSize, defaultRecentStartDate, defaultRecentendDate]);
    
    const { lastSevenFirst, lastSevenLast } = useMemo(() => {
        var result = [];
        for (var i = 0; i < 7; i++) {
            var d = new Date();
            result.push(d.setDate(d.getDate() - i));
        }
        const lastSevenFirst = new Date(result[0]);
        const lastSevenLast = new Date(result[result.length - 1]);
        
        setDefaultRecentStart(lastSevenLast);
        setDefaultRecentend(lastSevenFirst);
    
        return {
            lastSevenFirst,
            lastSevenLast
        };
    }, []);

    useEffect(()=>{
        setDefaultRecentStart(lastSevenLast);
        setDefaultRecentend(lastSevenFirst);
    },[])
    
    
   


    const configData = pageState?.data?.map((config) => ({
        id: config.registrationNo,
        Amount: currencyconverter(config?.totalAmount),
        totalCount:config?.totalCount,
        registrationNo: config?.registrationNo,
    }))

    // recent transactions columns

    const recentTransactionsColumns = [
        { field: "registrationNo", headerName: "Reg No", flex: 1 },
        { field: "totalCount", headerName: "Transaction Count", flex: 1 },
        { field: 'Amount', headerName: 'Amount', flex: 1 },
    ]
    return (
        <Grid container height={'100%'} direction={'column'}>
            <Grid item width={'100%'}>
                <Grid container width={'100%'} gap={1} height={'100%'}>
                    <Grid item width={'25%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(23, 174, 123, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(23, 174, 123, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography>Collections Today</Typography>
                                    {group === 'Merchant' ? null :
                                    <FormControl size="small" style={{ background: 'rgba(0, 0, 0, 0.03)' }} sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-label">Date Filter</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Date Filter"
                                        >
                                            <MenuItem value=''>All</MenuItem>
                                            <MenuItem value={'Today'}>Today</MenuItem>
                                            <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                            <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                            <MenuItem value={'This Month'}>This Month</MenuItem>
                                            <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                        </Select>
                                    </FormControl>}
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#17ad7a' }}>{currencyconverter(cardData?.dailyTransactions ? cardData?.dailyTransactions : 0)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>{cardData?.dailyTransactionCount} Transactions</Typography>
                                        </Grid>
                                        <Grid item>
                                            <ReceiptLongIcon style={{ color: '#fff', background: '#17ad7a', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item width={'25%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(61, 162, 248, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(61, 162, 248, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography pt={2} variant='body1'>Collections This Month</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700 , color:'#dc3545' }}>{cardData?.currentMonthTransaction}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>{cardData?.monthlyTransactionCount}  transactions</Typography>
                                        </Grid>
                                        <Grid item>
                                           <ReceiptLongIcon style={{ color: '#fff', background: '#dc3545', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item width={'23%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(220, 53, 69, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(220, 53, 69, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography pt={2} variant='body1'>Vehicle Owners</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#3da2f8' }}>{cardData?.vehicleOwnersCount}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>{cardData?.vehicleCount}  Vehicles</Typography>
                                        </Grid>
                                        <Grid item>
                                        <DirectionsCarFilledSharpIcon style={{ color: '#fff', background: '#3da2f8', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item width={'23%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(247, 144, 9, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(247, 144, 9, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography pt={2} variant='body1'>Operators</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#f79009' }}>{cardData.operatorsCount}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>Recently added :</Typography>
                                        </Grid>
                                        <Grid item>
                                            <PeopleAltIcon style={{ color: '#fff', background: '#f79009', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'100%'}>
                <Grid container direction={'column'} gap={2}>
                    <Grid item width={'100%'}>
                        <Typography pt={2} variant='body1' style={{ fontWeight: 700, fontSize: '20px' }}>Recent Transactions</Typography>
                    </Grid>
                    <Grid item width={'100%'}>
                        <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={recentTransactionsColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}