import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material"
import DescriptionIcon from '@mui/icons-material/Description';
import { useEffect, useState } from "react";
import SearchFilter from "../customerAccounts/customerComponents/searchFilter";
import CustomDataGrid from "../products/stockComponents/customDataGrid";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HttpComponent from "../School/MakeRequest";

const MTNdashboard = () => {
    const [todaysTransactions, setTodaysTransactions] = useState('Today')
    const [todaysendDateTransactions, setTodaysEnddateTransactions] = useState('Today')
    const [searchValue, setSearchValue] = useState('')


    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

    const TransactionsColumns = [
        { field: "transactionID", headerName: "Transaction ID", flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1 },
        { field: "mtnID", headerName: "MTN ID", flex: 1 },
        { field: "zedID", headerName: "ZED ID", flex: 1 },
        { field: "phone", headerName: "Phone Number", flex: 1 },
        { field: "date", headerName: "Date", flex: 1 },

    ]

    const TransactionsData = pageState?.data?.map((config) => ({
        id: config._id,
        transactionID: config?.transactionID,
        amount: config?.phone,
        mtnID: config?.mtnID,
        zedID: config?.zedID,
        phone: config?.phone,
        date: config?.createdAt,
    }))

    const AgentsColumns = [
        { field: "agentName", headerName: "Agent Name", flex: 1 },
        { field: 'phone', headerName: 'Phone Number', flex: 1 },
        { field: "merchantcode", headerName: "Agent Merchanant Code", flex: 1 },
        { field: "glAccount", headerName: "GL Account", flex: 1 },
    ]

    const agentsData = pageState?.data?.map((config) => ({
        id: config._id,
        agentName: config?.agentName,
        phone: config?.phone,
        merchantcode: config?.merchantcode,
        glAccount: config?.glAccount,
    }))

    const setOverallPerfomanceDates = (e) => {
        switch (e.target.value) {
            case "Today":
                const today = new Date().toLocaleDateString();
                setTodaysTransactions(today)
                setTodaysEnddateTransactions(today)
                break;
            default:
                setTodaysTransactions('')
                setTodaysEnddateTransactions('')
                break;
        }
    }
    function handleFetchAgents() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/agents?page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((res) => {
                if (res?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, data: res?.data?.data, count: res?.data?.count })
                }
            }).catch((err) => {
                console.log(err)
                setPageState({ ...pageState, isLoading: false, data: [], count: 0 })
            })

        } catch (err) {
            console.log(err)
            setPageState({ ...pageState, isLoading: false, data: [], count: 0 })
        }
    }
    function handleFetchTransactions() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/mtntransactions?page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((res) => {
                if (res?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, data: res?.data?.data, count: res?.data?.count })
                }
            }).catch((err) => {
                console.log(err)
                setPageState({ ...pageState, isLoading: false, data: [], count: 0 })
            })

        } catch (err) {
            console.log(err)
            setPageState({ ...pageState, isLoading: false, data: [], count: 0 })
        }
    }
    useEffect(() => {
        handleFetchTransactions()
        handleFetchAgents()
    }, [pageState.page, dataGridPageSize])
    return (
        <Grid direction={'column'} container>
            <Grid item width={'100%'} display={'flex'}>
                <Grid display={'flex'} width={'100%'} spacing={4} container direction={'row'}>
                    <Grid item xl={4} lg={4} sm={12} xs={12}>
                        <Paper elevation={2} sx={{ width: '100%', height: "150px" }}>
                            <Box component={'div'} sx={{ width: '100%', height: "100%", px: 1, display: "flex", alignItems: "center" }}>
                                <Box component={'div'} sx={{ width: '100%', gap: 1, height: "100%", flexDirection: 'column', display: "flex", flex: 0.6, alignItems: "flex-start", justifyContent: "center" }}>
                                    <Typography variant="h6" fontSize={12} fontWeight={600} sx={{ color: '#009447' }}>Today's Performance</Typography>
                                    <Typography variant="p" fontSize={14} color={'#92959e'}>Total Amount</Typography>
                                    <Typography variant="h6" fontSize={18} sx={{ color: '#009447' }}>UGX 100,000</Typography>
                                    <Typography variant="p" fontSize={14} display={'flex'} gap={1} alignItems={'center'}>
                                        <span style={{ fontSize: "12px" }}>Total Transactions</span>
                                        <span style={{ fontWeight: 700 }}>1000</span>
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ width: '100%', height: "100%", flexDirection: 'column', display: "flex", flex: 0.4, alignItems: "center", justifyContent: "center" }}>
                                    <DescriptionIcon sx={{ fontSize: '50px', color: "#009447", paddingX: '10px', paddingY: '10px', borderRadius: "10px", background: "#e5f4ec" }} />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xl={4} lg={4} sm={12} xs={12}>
                        <Paper elevation={2} sx={{ width: '100%', height: "150px" }}>
                            <Box component={'div'} sx={{ width: '100%', height: "100%", px: 1, display: "flex", alignItems: "center" }}>
                                <Box component={'div'} sx={{ width: '100%', gap: 1, height: "100%", flexDirection: 'column', display: "flex", flex: 0.6, alignItems: "flex-start", justifyContent: "center" }}>
                                    <Typography variant="h6" fontSize={12} fontWeight={600} sx={{ color: '#00b7fe' }}>This Week Performance</Typography>
                                    <Typography variant="p" fontSize={14} color={'#92959e'}>Total Amount</Typography>
                                    <Typography variant="h6" fontSize={18} sx={{ color: '#00b7fe' }}>UGX 100,000</Typography>
                                    <Typography variant="p" fontSize={14} display={'flex'} gap={1} alignItems={'center'}>
                                        <span style={{ fontSize: "12px" }}>Total Transactions</span>
                                        <span style={{ fontWeight: 700 }}>1000</span>
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ width: '100%', height: "100%", flexDirection: 'column', display: "flex", flex: 0.4, alignItems: "center", justifyContent: "center" }}>
                                    <DescriptionIcon sx={{ fontSize: '50px', color: "#00b7fe", paddingX: '10px', paddingY: '10px', borderRadius: "10px", background: "#f6fcff" }} />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xl={4} lg={4} sm={12} xs={12}>
                        <Paper elevation={2} sx={{ width: '100%', height: "150px" }}>
                            <Box component={'div'} sx={{ width: '100%', height: "100%", px: 1, display: "flex", alignItems: "center" }}>
                                <Box component={'div'} sx={{ width: '100%', gap: 1, height: "100%", flexDirection: 'column', display: "flex", flex: 0.6, alignItems: "flex-start", justifyContent: "center" }}>
                                    <Typography variant="h6" fontSize={12} fontWeight={600} sx={{ color: '#551fff' }}>This Month's Performance</Typography>
                                    <Typography variant="p" fontSize={14} color={'#92959e'}>Total Amount</Typography>
                                    <Typography variant="h6" fontSize={18} sx={{ color: '#551fff' }}>UGX 100,000</Typography>
                                    <Typography variant="p" fontSize={14} display={'flex'} gap={1} alignItems={'center'}>
                                        <span style={{ fontSize: "12px" }}>Total Transactions</span>
                                        <span style={{ fontWeight: 700 }}>1000</span>
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ width: '100%', height: "100%", flexDirection: 'column', display: "flex", flex: 0.4, alignItems: "center", justifyContent: "center" }}>
                                    <DescriptionIcon sx={{ fontSize: '50px', color: "#551fff", paddingX: '10px', paddingY: '10px', borderRadius: "10px", background: "#f7f5ff" }} />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12} width={'100%'} pt={3}>
                <Grid container spacing={2} direction={'column'}>
                    <Grid item display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography>Recent Transactions</Typography>
                        <FormControl size="small" style={{ background: 'rgba(0, 0, 0, 0.03)' }} sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-label">Today</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Today"
                                onChange={setOverallPerfomanceDates}
                            >
                                <MenuItem value={'Today'}>Today</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item width={'50%'}>
                        <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                    </Grid>
                    <Grid item width={'100%'} mt={2}>
                        <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={TransactionsColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={TransactionsData} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12} width={'100%'} pt={3}>
                <Grid container spacing={2} direction={'column'}>
                    <Grid item display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography>Agents</Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>Create Agent <ArrowForwardIosIcon /> </Typography>
                    </Grid>
                    <Grid item width={'100%'} mt={2}>
                        <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={AgentsColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={agentsData} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export { MTNdashboard }