import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import BusinessesDropdown from "./BusinessesDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { setDetails } from "../../../../features/businessUpdatedSlice";
import { setBusinesses } from "../../../../features/ExporterSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function ActiveTable() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count , setCount] = useState(0)
  const [data, setData] = useState([]);
  const group = localStorage.getItem('group')
  const [rows, setRows] = useState([]);
  console.log(rows , 'rows ##')

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const dispatch = useDispatch();

  const itemDeactivated = useSelector((state) => state.alertState.deactivated);
  const module = useSelector((state) => state.dropdownAction.module);
  const startDate = useSelector((state) => state.selectedDate.startDate2);
  const endDate = useSelector((state) => state.selectedDate.endDate2);

  let partnerName = localStorage.getItem("affiliatedPartner");

  const getActiveBusinesses = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        baseUrl + `/api/getActiveBusinesses?page=${page}&limit=${rowsPerPage}`,
        {
          module,
          partnerName,
          startDate,
          endDate
        },
        {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      setData(response?.data?.data);
      setCount(response?.data?.count)
    } catch (error) {
      console.error("Error in getActiveBusinesses:", error);
      // Handle the error, e.g., setLoading(false) or show an error message
    }
  };

  useEffect(() => {
    console.log('Fetching data with:', { page, rowsPerPage, module, partnerName, startDate, endDate });

    getActiveBusinesses();
  }, [itemDeactivated, module, endDate , rowsPerPage, page]);

  let searchQuery = useSelector((state) => state.search.searchValue);

  let filteredData;

  const reduxSearchBy = useSelector((state) => state.dropdownAction.searchBy7);

  useEffect(() => {
    if (reduxSearchBy === "businessName") {
      filteredData = data.filter((item) =>
        item.businessName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }else if (reduxSearchBy === 'partnerRegion') {
      filteredData = data.filter((item) =>
        item?.regionName?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
    }else if (reduxSearchBy === 'partnerBranch') {
      filteredData = data.filter((item) =>
        item?.regionBranchName?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
    }else if (reduxSearchBy === 'name') {
      filteredData = data.filter((item) =>
        item?.salesPName?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
    }else if (reduxSearchBy === 'code'){
      filteredData = data.filter((item) =>
        item?.salesPCode?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
    }else if (reduxSearchBy === "dateCreated") {
      filteredData = data.filter((item) => {
        let dateString = item.createdAt;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-US");
        return formattedDate.toLowerCase().includes(searchQuery.toLowerCase());
      });

    }
  }, [searchQuery]);

  //creating rows from the fetched data
  const createRows = () => {
    let arr = [];
    console.log('Data before processing:', data);
    if (searchQuery.length === 0) {
      data?.map((obj) => {
        console.log(obj, 'fkkkkk')
        let dateString = obj.createdAt;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-US");

        let val = createData(
          obj.businessName,
          obj.businessNumber,
          obj.businessOwnerName,
          obj.businessOwnerEmail,
          obj.businessOwnerPhone,
          obj.terminalCount,
          obj.partner,
          group === 'Partner' ? obj.salesPName ?? 'N/A' : null,
          group === 'Partner' ? obj.salesPCode ?? 'N/A' : null,
          group === 'Partner' ? '' : obj.branchCount,
          obj.regionName,
          obj.regionBranchName,
          obj.partnerRegion,
          obj.partnerBranch,
          formattedDate,
          <BusinessesDropdown />,
        );
        console.log('Created row:', val);
        arr.push(val);
      });
    } else {
      filteredData?.map((obj) => {
        let dateString = obj.createdAt;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US');

        let val = createData(
          obj.businessName,
          obj.businessNumber,
          obj.businessOwnerName,
          obj.businessOwnerEmail,
          obj.businessOwnerPhone,
          obj.terminalCount,
          obj.partner,
          group === 'Partner' ? obj.salesPName ?? 'N/A' : null,
          group === 'Partner' ? obj.salesPCode ?? 'N/A' : null,
          group === 'Partner' ? '' : obj.branchCount,
          obj.regionName,
          obj.regionBranchName,
          obj.partnerRegion,
          obj.partnerBranch,
          formattedDate,
          <BusinessesDropdown />,
        );
        arr.push(val);
      });
    }

    // arr.sort((a, b) => (a.businessID < b.businessID ? 1 : -1));
    console.log('Final array before setting rows:', arr);

    setRows(arr);
  };

  useEffect(() => {
    createRows();
  }, [data, searchQuery]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(rows , 'here rows #')
    if (rows.length > 0) {
      setLoading(false);
      const newArray = rows.map(({ Action, ...rest }) => rest);
      dispatch(setBusinesses(newArray));
    }
  }, [rows]);

  useEffect(() => {
    const delayedFunction = () => {
      // This function runs 5 seconds after initial load;
      if (rows.length === 0) {
        setLoading(false);
      }
    };

    const timer = setTimeout(delayedFunction, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [rows]);

  // console.log("lesRows");
  // console.log(rows);
  return (
    <TableContainer component={Paper} width={"100%"}>
      {loading ? (
        <Stack width="100%" color={"#032541"}>
          <LinearProgress color="inherit" />
        </Stack>
      ) : null}
      <Table
        sx={{ minWidth: 500, fontFamily: "Poppins" }}
        aria-label="custom pagination table"
        size={"small"}
      >
        <TableHead sx={{ bgcolor: "#f0f0f0" }}>
          <TableRow>
            <TableCell>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Business Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Business ID
              </Typography>
            </TableCell>
            {group === 'Partner' ? null : (
              <>
                <TableCell align="center">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="500"
                    fontStyle="normal"
                    textAlign="left"
                    color="#032541"
                  >
                    Owner
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="500"
                    fontStyle="normal"
                    textAlign="left"
                    color="#032541"
                  >
                    Email
                  </Typography>
                </TableCell>
              </>
            )}
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Phone No
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Terminals
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Partner
              </Typography>
            </TableCell>
            {group === 'Partner' ? (
              <>
                <TableCell align="center">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="500"
                    fontStyle="normal"
                    textAlign="left"
                    color="#032541"
                  >
                    Salesperson
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="500"
                    fontStyle="normal"
                    textAlign="left"
                    color="#032541"
                  >
                    Salesperson Code
                  </Typography>
                </TableCell>
              </>
            ) : null}
            {group === 'Partner' ? null : (
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="500"
                  fontStyle="normal"
                  textAlign="left"
                  color="#032541"
                >
                  Branches
                </Typography>
              </TableCell>
            )}

            {group === 'Partner' ? (
              <>
                <TableCell align="center">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="500"
                    fontStyle="normal"
                    textAlign="left"
                    color="#032541"
                  >
                    Region
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="500"
                    fontStyle="normal"
                    textAlign="left"
                    color="#032541"
                  >
                    Branch
                  </Typography>
                </TableCell>
              </>
            ) : null}



            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Date Created
              </Typography>
            </TableCell>


            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <>
              <TableRow
                key={row.BusinessID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  let details = {
                    businessId: row.BusinessID,
                    businessName: row.BusinessName,
                    owner: row.Owner,
                    email: row.Email,
                    phoneNumber: row.PhoneNo,
                    partnerRegion: row?.partnerRegion ? row?.partnerRegion : 'N/A',
                    partnerBranch: row?.partnerBranch ? row?.partnerBranch : 'N/A',
                    salesPCode: row?.salesPCode ? row?.salesPCode : 'N/A',
                    salesPName: row?.salesPName ? row?.salesPName : 'N/A',
                  };
                  dispatch(setDetails(details));
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="normal"
                    fontStyle="normal"
                    textAlign="left"
                    color="#707070"
                  >
                    {row.BusinessName}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="normal"
                    fontStyle="normal"
                    textAlign="start"
                    color="#707070"
                  >
                    {row.BusinessID}
                  </Typography>
                </TableCell>
                {group === 'Partner' ? null:(
                  <>
                    <TableCell align="left">
                      <Typography
                        fontFamily="Poppins"
                        fontSize={14}
                        fontWeight="normal"
                        fontStyle="normal"
                        textAlign="start"
                        color="#707070"
                      >
                        {row.Owner}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        fontFamily="Poppins"
                        fontSize={14}
                        fontWeight="normal"
                        fontStyle="normal"
                        textAlign="start"
                        color="#707070"
                        sx={{ width: "100px", overflow: "scroll" }}
                      >
                        {row.Email}
                      </Typography>
                    </TableCell></>
                )}
                <TableCell align="left">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="normal"
                    fontStyle="normal"
                    textAlign="start"
                    color="#707070"
                  >
                    {row.PhoneNo}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="normal"
                    fontStyle="normal"
                    textAlign="center"
                    color="#707070"
                  >
                    {row.Terminals}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="normal"
                    fontStyle="normal"
                    textAlign="left"
                    color="#707070"
                  >
                    {row.Partner || "N/A"}
                  </Typography>
                </TableCell>
                {group === 'Partner' ? (
                  <>
                    <TableCell align="left">
                      <Typography
                        fontFamily="Poppins"
                        fontSize={14}
                        fontWeight="normal"
                        fontStyle="normal"
                        textAlign="left"
                        color="#707070"
                      >
                        {row.salesPName}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        fontFamily="Poppins"
                        fontSize={14}
                        fontWeight="normal"
                        fontStyle="normal"
                        textAlign="start"
                        color="#707070"
                      >
                        {row.salesPCode}
                      </Typography>
                    </TableCell></>
                ):null} 
               
                {group === 'Partner' ? null : (
                  <TableCell align="center">
                    <Typography
                      fontFamily="Poppins"
                      fontSize={14}
                      fontWeight="normal"
                      fontStyle="normal"
                      textAlign="center"
                      color="#707070"
                    >
                      {row.Branches}
                    </Typography>
                  </TableCell>)}


               
                {group === 'Partner' ? (
                  <>
                    <TableCell align="center">
                      <Typography
                        fontFamily="Poppins"
                        fontSize={14}
                        fontWeight="normal"
                        fontStyle="normal"
                        textAlign="left"
                        color="#707070"
                      >
                        {row.Region}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        fontFamily="Poppins"
                        fontSize={14}
                        fontWeight="normal"
                        fontStyle="normal"
                        textAlign="left"
                        color="#707070"
                      >
                        {row.Branch}
                      </Typography>
                    </TableCell>
                  </>
                ) : null}
                 <TableCell align="left">
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="normal"
                    fontStyle="normal"
                    textAlign="center"
                    color="#707070"
                  >
                    {row.DateCreated}
                  </Typography>
                </TableCell>
                <TableCell align="left">{row.Action}</TableCell>
              </TableRow>
            </>
          ))}
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={9} />
            </TableRow>
          )} */}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100, 200 , 500]}
              colSpan={10}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={(event, newPage) => {
                // console.log('Page changed to:', newPage + 1);
                setPage(newPage + 1);
              }}
              onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10);
                // console.log('Rows per page changed to 🚶‍♂️‍➡️:', newRowsPerPage);
                setRowsPerPage(newRowsPerPage);
                setPage(1);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
// Business Name, Business ID, Phone No, Terminals, Partner, Salesperson, Salesperson Code,  Region, Branch, Date Created and Actions
function createData(BusinessName, BusinessID, Owner, Email, PhoneNo, Terminals, Partner, salesPName, salesPCode, Branches, Region, Branch, partnerRegion, partnerBranch, DateCreated, Action) {
  const group = localStorage.getItem('group');
  return {
    BusinessName,
    BusinessID,
    Owner,
    Email,
    PhoneNo,
    Terminals,
    Partner,
    salesPName: group === 'Partner' ? salesPName : '',
    salesPCode: group === 'Partner' ? salesPCode : '',
    Branches: group === 'Partner' ? '' : Branches,
    Region,
    Branch,
    partnerRegion: group === 'Partner' ? partnerRegion : '',
    partnerBranch: group === 'Partner' ? partnerBranch : '',
    DateCreated,
    Action,
  };
}
