import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomTextField from "../../School/CustomTextField";
import HttpComponent from "../../School/MakeRequest";
import CustomSelectField from "../../School/CustomSelectField";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { changeToken } from "../../../features/userSlice";
import GeoLocation from '../../../hooks/useLocation';
import axios from 'axios';
import { handleSetBusinessData } from '../../../features/tenantBusinessSlice';
import { handleBusinessBranches } from '../../../features/businessBranchesSlice';
const baseUrl = process.env.REACT_APP_BASE_URL;
const GeneralBusinessDetails = (props) => {
    const { category } = useSelector((store) => store.category);
    //   const [businessType, setBussinessType] = useState([]);
    const [formData, setFormData] = useState({
        businessType: category,
        businessName: "",
        businessOwner: "",
        phoneNumber: "",
        email: "",
        location: "",
        country: "",
        currency: ""
    })

    // get business types
    const getBusinessTypes = () => {
        HttpComponent({
            method: 'GET',
            url: '/api/listBusinessCategory',
            body: null,
            token: X_Authorization,
        }).then((data) => {
            console.log("here store is data", data);
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        getBusinessTypes()
    }, [])

    const handleInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };
    console.log(formData, 'formdataaaaaaaa')
    const cancelButtonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "padding": "12.5px 36.7px 12.5px 38.3px",
        "borderRadius": "4px",
        "border": "solid 1px #dc3545",
        "color": "#dc3545"
    }

    const doneButtonStyles = {
        "width": "7.813rem",
        "height": "2.813rem",
        "padding": "12.5px 43.7px 12.5px 45.3px",
        "borderRadius": "4px",
        "backgroundColor": "#17ae7b",
        "color": "white",
        "marginLeft": 2,
        '&:hover': {
            backgroundColor: '#17ae7b',
            color: 'white'
        }
    }

    const distributorOptions = [
        { value: "Distributor", label: "Distributor" },
    ]

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { userId, X_Authorization } = useSelector((store) => store.user);

    const [errorShow, setErrorShow] = useState({ state: false, message: "" })


    const [showBusinessDetails, setShowBusinessDetails] = useState(false);
    const [selectedValue, setSelectedValue] = useState('')
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [counrty, setCount] = useState(null)



    const fetchBranches = async (token) => {
        try {
          const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
            method: "GET",
            headers: {
              "X-Authorization": token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          const data = await response.json();
          if (response?.status === 200) {
            //console.log(data?.data , 'lets go >>>>>>>>>>>>')
            dispatch(handleBusinessBranches({ branchesArr: data.data }));
            dispatch(handleSetBusinessData(data.data))

          }
        } catch (e) {
          console.log(e);
        }
      };


    const submitBusinessDetails = () => {
        let submitObject = {
            businessName: formData.businessName,
            businessCategory: "Eatery",
            businessOwnerName: formData.businessOwner,
            businessOwnerUserName: formData.businessOwner.split(" ").join(""),
            businessOwnerPhone: formData.phoneNumber,
            businessOwnerEmail: formData.email,
            businessOwnerAddress: selectedValue?.description,
            //businessOwnerAddress: formData.location,
            country: selectedCountry,
            localCurrency: selectedCurrency,
            deviceCreatedOn: "Web",
            userId: userId,
            Paybill: "",
            Vooma: "",
            Equitel: "",
            Till: ""
        }

        console.log("data", submitObject)

        HttpComponent({
            method: 'POST',
            url: '/postBusiness',
            body: submitObject,
            token: X_Authorization
        }).then(async(data) => {
            console.log("here store is data", data);
            if (data.status === 201) {
                dispatch(changeToken({ token: data.response.data.token }))
                localStorage.setItem("X-Authorization", data.response.data.token);
                localStorage.setItem("businessCategory", "Eatery, Bar and Restaurant")
                localStorage.setItem("businessId", data.response.businessNumber)
                localStorage.setItem("businessID", data.response.businessId)
                localStorage.setItem("businessName", data.response.data.businessName)
                localStorage.setItem("BranchID", data.response.data.branchId)
                await fetchBranches(data?.response?.data?.token)

                //window.location.reload()
                props.next()


            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    const [validEmail, setValidEmail] = useState(true);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    //fetch countries and currency
    const [allCoutries, setAllCountries] = useState([])


    const getCurrencyandCountry = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all?fields=name,currencies', { headers: { "Content-Type": "application/json", "Accept": "application/json" } })

            if (response.status === 200) {
                setAllCountries(response.data)
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        getCurrencyandCountry()
    }, [])

    const allCountry = [{
        "name": {
            "common": "Kenya",
        },
        "currencies": {
            "KES": {
                "name": "Kenyan shilling",
                "symbol": "Sh"
            }
        }
    },
    {
        "name": {
            "common": "Uganda",
        },
        "currencies": {
            "UGX": {
                "name": "Ugandan shilling",
                "symbol": "Sh"
            }
        }
    },
    {
        "name": {
            "common": "Nigeria",
        },
        "currencies": {
            "NGN": {
                "name": "Nigerian naira",
                "symbol": "₦"
            }
        }
    }
]


    const filteredCountries = allCountry?.filter(country => {
        const commonName = country?.name?.common;
        return commonName === "Kenya" || commonName === "Uganda" || commonName === "Nigeria";
    });

    const currencies = filteredCountries?.map(country => {
        const commonName = country?.name?.common;
        const currencyData = country?.currencies[Object.keys(country.currencies)[0]];
        return {
            country: commonName,
            currency: currencyData.name,
            currencyCode: Object.keys(country.currencies)[0],
            currencySymbol: currencyData.symbol
        };
    });

    const handleCountryChange = (event) => {
        const selectedValue = event.target.value;
        const returnCurreny =  currencies?.find((currency) => currency.country === selectedValue )
        setCount(selectedValue)
        setSelectedCountry(returnCurreny?.country);
        setSelectedCurrency(returnCurreny.currencyCode);
    };

    useEffect(() => {
        if (formData.email != "") {
            let result = validateEmail(formData.email);
            setValidEmail(result);
            // console.log("email valid", result)
        } else {
            setValidEmail(true);
        }
    }, [formData.email]);
    return (
        <Box component="div" sx={{ display: "flex", justifyContent: "center", margin: 2, width: "100%" }}>
            <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%", alignSelf: "center" }}>

                <ErrorAlert
                    vertical="top"
                    horizontal="right"
                    onClose={() => setErrorShow({ ...errorShow, state: false })}
                    open={errorShow.state}
                    message={errorShow.message} />


                {!showBusinessDetails ?
                    <Box sx={{ alignSelf: "center" }}>
                        {/*title*/}
                        <Box component="div" sx={{ alignSelf: "center", marginY: 2 }}>
                            <Typography sx={{ fontSize: "1rem", color: "#707070", justifyContent: 'center', display: 'center' }}>
                                Set-up your business details.
                            </Typography>
                        </Box>
                        <Grid container direction={'column'} alignContent={'center'} justifyContent={'center'} display={'flex'}>
                            <Grid item>
                                <Grid container display={'flex'} spacing={2} alignItems={'center'}>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"Business Name"} />
                                        <CustomTextField value={formData.businessName} onChange={handleInputChange} name={"businessName"} placeholder={"Business Name"} />
                                    </Grid>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"Owners"} />
                                        <CustomTextField value={formData.businessOwner} onChange={handleInputChange} name={"businessOwner"} placeholder={"Directors"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container display={'flex'} alignItems={'center'} spacing={2}>
                                    <Grid item width={'50%'}>
                                        <CustomInputLabel required={true} label={"Phone Number"} />
                                        <PhoneInput inputStyle={{ height: '3.438rem', width: "100%" }} required country={"ke"} enableSearch={true} value={formData.phoneNumber} name={"phoneNumber"} onChange={(phone) => formData.phoneNumber = phone} />
                                    </Grid>
                                    <Grid item display={'flex'} direction={'column'} width={'50%'}>
                                        <CustomInputLabel required={true} label={"Email"} />
                                        {!validEmail ?
                                            <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                                Please enter a valid email
                                            </Typography>
                                            :
                                            null
                                        }
                                        <CustomTextField  value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container display={'flex'} spacing={3} alignItems={'center'}>
                                    <Grid item width={'50%'}>
                                         <CustomInputLabel required={true} label={"Select Country"} />
                                        <FormControl sx={{ marginTop: "10px",marginBottom: "10px", display: "flex" }} width={'100%'}>
                                            <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counrty}
                                                label="Select Country"
                                                onChange={handleCountryChange}
                                            >
                                                {currencies?.map((item) => (
                                                    <MenuItem key={item?.currencyCode} value={item?.country}>{item?.country}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item width={'50%'}>
                                        <CustomInputLabel required={true} label={"Location"} />
                                        <GeoLocation sx={{ marginBottom: "10px", width: "100%", marginTop: "10px" }} selectedValue={selectedValue} onValueChange={(location) => setSelectedValue(location)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item alignItems={'center'} display={'flex'}>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"Country"} />
                                        <CustomTextField value={selectedCountry} name={"currency"} placeholder={"Select Currency"} />
                                    </Grid>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"Currency"} />
                                        <CustomTextField value={selectedCurrency} name={"currency"} placeholder={"Select Currency"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*    end of form */}
                        <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                            <Box component="div">
                                <Button sx={cancelButtonStyles} onClick={() => navigate("/createBusiness")}>
                                    Cancel
                                </Button>

                                <Button
                                    disabled={ !formData.email || !formData.businessName || !formData.phoneNumber || !selectedValue || !formData.businessOwner || !selectedCountry || !selectedCurrency}
                                    sx={doneButtonStyles} onClick={() => setShowBusinessDetails(true)}>
                                    Done
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    :
                    <Box sx={{ alignSelf: "center", width: "100%" }}>
                        {/*title*/}
                        <Box component="div" sx={{ display: "flex", width: "100%", justifyContent: "center", alignSelf: "center", marginY: 2 }}>
                            <Typography sx={{ alignSelf: "center", fontSize: "1rem", color: "#17ae7b", fontWeight: 700 }}>
                                Business Summary
                            </Typography>
                        </Box>
                        {/*    end of title*/}


                        {/*begin of form*/}
                        <Box compnent="div" sx={{ display: "flex", width: "100%", justifyContent: "center", alignSelf: "center" }}>
                            {/*column one*/}
                            <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 2, width: "35%" }}>
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Business Type
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {formData.businessType}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Business Name
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {formData.businessName}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Director
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {formData.businessOwner}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Phone Number
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {"+" + formData.phoneNumber}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                            </Box>
                            {/*end column onw*/}

                            {/*column two*/}
                            <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 2, width: "35%" }}>
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Email
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {formData.email}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Location
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {selectedValue?.description}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Country
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {selectedCountry}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Currency
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {selectedCurrency}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>
                            </Box>
                            {/*end column two*/}

                        </Box>
                        <Box component="div" sx={{ width: "86%", display: "flex", justifyContent: "end", marginTop: 1 }}>
                            <Box component="div">
                                <Button sx={
                                    {
                                        "width": "7.813rem",
                                        "height": "2.813rem",
                                        "padding": "12.5px 36.7px 12.5px 38.3px",
                                        "borderRadius": "4px",
                                        "border": "solid 1px #032541",
                                        "color": "#032541"
                                    }} onClick={() => setShowBusinessDetails(false)}>
                                    Edit
                                </Button>

                                <Button sx={doneButtonStyles} onClick={submitBusinessDetails}>
                                    Next
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                }


            </Box>
        </Box>
    )
}

export default GeneralBusinessDetails;