import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import HttpComponent from "./MakeRequest";
import CustomSelectField from "./CustomSelectField";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { changeToken } from "../../features/userSlice";
import CustomDropZone from './CustomDropZone';
import LoadingButton from "@mui/lab/LoadingButton";
import Upload from './Upload';
import Modal from '@mui/material/Modal';
import modalIcon from './Images/madalIcon.svg';
import warningIcon from './Images/warning-remove-icn.svg'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import icon from "./Images/upload.svg"
import { handleSchoolTypeName, removeSchoolTypeName } from "../../features/schoolType";
import axios from 'axios';
import { handleBusinessBranches } from '../../features/businessBranchesSlice';
import { handleSetBusinessData } from '../../features/tenantBusinessSlice';
const baseUrl = process.env.REACT_APP_BASE_URL;

const SchoolDetailsForm = (props) => {
    const schoolTypeState = localStorage.getItem("schoolTypeState")
    const schoolTypeStateId = localStorage.getItem("schoolTypeStateId")
    const [formData, setFormData] = useState({
        schoolType: schoolTypeStateId,
        schoolName: "",
        schoolOwner: "",
        phoneNumber: "",
        email: "",
        location: "",
        country: "",
        currency: ""
    })
    const [showUploadForm, setShowUploadForm] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const imageUploader = useRef(null);
    const uploadedImage = useRef(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState()


    // handling modal operations
    // OPEN MODAL
    const handleModelOpen = () => {
        setIsModalOpen(true)
    }

    // CLOSE MODAL
    const handleModelClose = () => {
        setIsModalOpen(false);
        setShowUploadForm(!showUploadForm)
    }

    const notify = () => {
        toast.success("Logo Uploaded Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
        });
    }



    const handleFileInputChange = (e) => {
        const [file] = e.target.files;
        console.log("file", e.target.files)
        console.log("uploadedImage", uploadedImage)
        console.log("imageUploader", imageUploader)
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            const selectedFile = file;
            reader.onload = (e) => {
                current.src = e.target.result;
            }
            reader.readAsDataURL(file);
            setFile(file);
            setSelectedFile(selectedFile);
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFileInputChange(e);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const cancelButtonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "padding": "12.5px 36.7px 12.5px 38.3px",
        "borderRadius": "4px",
        "border": "solid 1px #dc3545",
        "color": "#dc3545"
    }

    const doneButtonStyles = {
        "width": "7.813rem",
        "height": "2.813rem",
        "padding": "12.5px 43.7px 12.5px 45.3px",
        "borderRadius": "4px",
        "backgroundColor": "#17ae7b",
        "color": "white",
        "marginLeft": 2,
        '&:hover': {
            backgroundColor: '#17ae7b',
            color: 'white'
        }
    }

    const skipModalStyle = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        width: '460px',
        height: '330px',
        padding: '35px',
        borderRadius: '12px',
        border: 'solid 1px #707070',
        backgroundColor: '#fff',
        top: '50%',
        left: '58%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
    };

    const deleteModalStyle = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '460px',
        height: '330px',
        padding: '35px',
        borderRadius: '12px',
        border: 'solid 1px #707070',
        backgroundColor: '#fff',
        top: '50%',
        left: '58%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
    };



    const [schoolOptions, setschoolOptions] = useState([]);
    const getSchoolTypes = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_types`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data)
        setschoolOptions(data.data.map((school) => {
            return { value: school._id, label: school.schoolType }
        }
        ))
    }


    useEffect(() => {
        getSchoolTypes();
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null)

    const { userId, X_Authorization } = useSelector((store) => store.user);

    const [errorShow, setErrorShow] = useState({ state: false, message: "" })


    const [showSchoolDetails, setShowSchoolDetails] = useState(false);
    const [img, setImg] = useState(null);

    const fetchBranches = async (token) => {
        try {
          const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
            method: "GET",
            headers: {
              "X-Authorization": token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          const data = await response.json();
          if (response?.status === 200) {
            //console.log(data?.data , 'lets go >>>>>>>>>>>>')
            dispatch(handleBusinessBranches({ branchesArr: data.data }));
            dispatch(handleSetBusinessData(data.data))

          }
        } catch (e) {
          console.log(e);
        }
      };

    const submitSchoolDetails = () => {
        let submitObject = {
            businessName: formData.schoolName,
            businessCategory: "School",
            businessOwnerName: formData.schoolOwner,
            businessOwnerUserName: formData.schoolOwner.split(" ").join(""),
            businessOwnerPhone: phoneNumber,
            businessOwnerEmail: formData.email,
            businessOwnerAddress: formData.location,
            country: selectedCountry,
            localCurrency: selectedCurrency,
            deviceCreatedOn: "Web",
            schooltypeId: formData.schoolType,
            userId: userId,
            Paybill: "",
            Vooma: "",
            Equitel: "",
            Till: "",
            logo: img
        }
        //    submitObject.append('logo', img);

        //console.log("data", submitObject)


        HttpComponent({
            method: 'POST',
            url: '/postBusiness',
            body: submitObject,
            token: X_Authorization
        }).then(async (data) => {
            console.log("here is business data", data);
            if (data.status === 201) {
                dispatch(changeToken({ token: data.response.data.token }))
                localStorage.setItem("X-Authorization", data.response.data.token);
                localStorage.setItem("businessCategory", "School")
                localStorage.setItem("businessId", data.response.businessNumber)
                localStorage.setItem("businessID", data.response.businessId)
                localStorage.setItem("businessName", data.response.data.businessName)
                localStorage.setItem("BranchID", data.response.data.branchId)
                localStorage.removeItem('logoImage');
                await uploadFile(selectedFile, data.response.data.token)
                await fetchBranches(data?.response?.data?.token)
                let schoolDetails = {
                    schoolTypeId: formData.schoolType,
                    schoolTypeName: schoolOptions.filter((school) => school.value === formData.schoolType)[0].label,
                }
                //console.log("school details -----", schoolDetails)
                dispatch(handleSchoolTypeName(schoolDetails))
                props.next()
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }


     //fetch countries and currency
     const [allCoutries, setAllCountries] = useState([])
     const [counrty, setCount] = useState(null)



     const getCurrencyandCountry = async () => {
         try {
             const response = await axios.get('https://restcountries.com/v3.1/all?fields=name,currencies', { headers: { "Content-Type": "application/json", "Accept": "application/json" } })
 
             if (response.status === 200) {
                 setAllCountries(response.data)
             }
 
         } catch (error) {
 
         }
     }
 
     useEffect(() => {
         getCurrencyandCountry()
     }, [])
 
 
     const allCountry = [{
        "name": {
            "common": "Kenya",
        },
        "currencies": {
            "KES": {
                "name": "Kenyan shilling",
                "symbol": "Sh"
            }
        }
    },
    {
        "name": {
            "common": "Uganda",
        },
        "currencies": {
            "UGX": {
                "name": "Ugandan shilling",
                "symbol": "Sh"
            }
        }
    },
    {
        "name": {
            "common": "Nigeria",
        },
        "currencies": {
            "NGN": {
                "name": "Nigerian naira",
                "symbol": "₦"
            }
        }
    }
]
//console.log(allCountry , )
     const filteredCountries = allCountry?.filter(country => {
         const commonName = country.name.common;
         return commonName === "Kenya" || commonName === "Uganda" || commonName === "Nigeria";
     });

    // console.log(filteredCountries , 'fil')
 
     const currencies = filteredCountries.map(country => {
         const commonName = country.name.common;
         const currencyData = country.currencies[Object.keys(country.currencies)[0]];
         return {
             country: commonName,
             currency: currencyData.name,
             currencyCode: Object.keys(country.currencies)[0],
             currencySymbol: currencyData.symbol
         };
     });
 
     const handleCountryChange = (event) => {
         const selectedValue = event.target.value;
         const returnCurreny =  currencies?.find((currency) => currency.country === selectedValue )
         setCount(selectedValue)
         setSelectedCountry(returnCurreny?.country);
         setSelectedCurrency(returnCurreny.currencyCode);
      
     };

    const [validEmail, setValidEmail] = useState(true);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // validate phone number

    const validatePhoneNumber = (phoneNumber) => {
        const cleanedPhoneNumber = phoneNumber.slice(3);
        return cleanedPhoneNumber.length === 9 || (cleanedPhoneNumber.startsWith('0') && cleanedPhoneNumber.slice(1).length === 9);
    }

    const [isValidPhone, setIsValidPhone] = useState(true)
    const [phoneNumber, setPhoneNumber] = useState('')


    //phone number change

    const handlePhoneChange = (value, country, e, formattedValue) => {
        setPhoneNumber(value);
        const isValid = validatePhoneNumber(value);
        setIsValidPhone(isValid);
        if (isValid) {
            e.target.blur();
        }
    };

    useEffect(() => {
        if (formData.email != "") {
            let result = validateEmail(formData.email);
            setValidEmail(result);
            // console.log("email valid", result)
        } else {
            setValidEmail(true);
        }
    }, [formData.email]);


    const logoImg = localStorage.getItem('logoImage');

    // get image from local storage
    useEffect(() => {
        setImg(localStorage.getItem('logoImage'));
    }, []);
    //console.log("school details", formData)
    const [schoolTypeName, setSchoolTypeName] = useState('');
    const id = formData.schoolType;
   // console.log("id", id);
    useEffect(() => {
        if (formData.schoolType !== '') {
            // const schoolType = schoolOptions.find((school) => school.value === id);
            setSchoolTypeName(schoolTypeState);

            getDefaultGrades();
        }

    }, [formData.schoolType])
    const [defaultGrades, setDefaultGrades] = useState([]);


    const getDefaultGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeStateId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, 'default grades')
        setDefaultGrades(data.data?.filter((grade)=> grade?.schoolGrades !== 'COMPLETED'))
    }

    const uploadFile = async(file, token) => {
        console.log("file businessLogo file  selectedFile=>", selectedFile)
        console.log("file businessLogo =>", file)

        const url = `${baseUrl}/api/edit_business_logo`; // Replace with your upload endpoint URL

        const xhr = new XMLHttpRequest();
        const formData = new FormData();

        formData.append('businessLogo', file);

        xhr.open('POST', url, true);
        xhr.setRequestHeader("X-Authorization", token);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    let data = JSON.parse(xhr.responseText);
                    console.log('File uploaded successfully');
                } else {
                    console.error('Failed to upload file');
                }
            }
        };

        xhr.send(formData);
    }



    return (
        <Box component="div" sx={{ display: "flex", justifyContent: "center", margin: 2, width: "100%" }}>
            <Box>
                <Modal
                    open={isModalOpen}
                    onClose={handleModelClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={skipModalStyle}>
                        <img src={modalIcon} alt="modalIcon" style={{
                            width: '56.5px',
                            height: '56.5px',
                            objectFit: 'contain'
                        }} />
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: 600,
                            fontStretch: ' normal',
                            fontStyle: ' normal',
                            lineHeight: ' normal',
                            letterSpacing: ' normal',
                            margin: '15px 0px',
                            color: '#032541'
                        }}>
                            Upload your Logo Later!
                        </Typography>
                        <Typography id="modal-modal-description" variant='p' style={{
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            fontStretch: ' normal',
                            fontStyle: ' normal',
                            lineHeight: ' normal',
                            letterSpacing: ' normal',
                            margin: '15px 0px',
                            color: '#707070'
                        }}>
                            You can upload your business logo later under <strong style={{ color: '#032541' }}>Business Info</strong> menu.
                        </Typography>
                        <Button style={{
                            width: '125px',
                            height: '45px',
                            margin: '31px 0px 0 0px',
                            padding: '12.5px 42.4px 12.5px 44.6px',
                            borderRadius: '4px',
                            backgroundColor: '#032541',
                            color: '#fff',
                            fontWeight: 600
                        }} onClick={handleModelClose}>Okay</Button>
                    </Box>
                </Modal>
            </Box>
            <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%", alignSelf: "center" }}>

                <ErrorAlert
                    vertical="top"
                    horizontal="right"
                    onClose={() => setErrorShow({ ...errorShow, state: false })}
                    open={errorShow.state}
                    message={errorShow.message} />


                {!showSchoolDetails ?
                    <Box sx={{ alignSelf: "center" }}>
                        {/*title*/}
                        <Box component="div" sx={{ alignSelf: "center", marginY: 2 }}>
                            <Typography sx={{ fontSize: "1rem", color: "#707070" }}>
                                Set-up your business details.
                            </Typography>
                        </Box>
                        <Grid container direction={'column'} alignItems={'center'}>
                            <Grid item>
                                <Grid container display={'flex'} alignItems={'center'} spacing={3}>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"School Type"} />
                                        <CustomTextField fw={"bold"} value={schoolTypeState} disabled={true} name={"schoolTypeState"} placeholder={schoolTypeState} />
                                    </Grid>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"School Name"} />
                                        <CustomTextField value={formData.schoolName} onChange={handleInputChange} name={"schoolName"} placeholder={"School Name"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container display={'flex'} alignItems={'center'} spacing={3}>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"Director"} />
                                        <CustomTextField value={formData.schoolOwner} onChange={handleInputChange} name={"schoolOwner"} placeholder={"School Owner"} />
                                    </Grid>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"Phone Number"} />
                                        {!isValidPhone ?
                                            <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                                Phone number should be 10 characters
                                            </Typography>
                                            :
                                            null
                                        }
                                        <PhoneInput inputStyle={{ height: '3.438rem', width: "25rem" }} required country={"ke"} enableSearch={true} value={phoneNumber} name={"phoneNumber"} onChange={handlePhoneChange} />
                                        {/* <PhoneInput inputStyle={{ height: '3.438rem', width: "25rem" }} required country={"ke"} enableSearch={true} value={formData.phoneNumber} name={"phoneNumber"} onChange={(phone) => formData.phoneNumber = phone} /> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container display={'flex'} alignItems={'center'} spacing={3}>
                                    <Grid item direction={'column'}>
                                        <CustomInputLabel required={true} label={"Email"} />
                                        {!validEmail ?
                                            <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                                Please enter a valid email
                                            </Typography>
                                            :
                                            null
                                        }
                                        <CustomTextField value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                                    </Grid>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"Location"} />
                                        <CustomTextField value={formData.location} onChange={handleInputChange} name={"location"} placeholder={"Location"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item width={'100%'} mt={1} mb={1}>
                                <FormControl sx={{ marginTop: "10px", display: "flex" }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={counrty}
                                        label="Select Country"
                                        onChange={handleCountryChange}
                                    >
                                        {currencies?.map((item) => (
                                            <MenuItem value={item?.country}>{item?.country}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Grid container display={'flex'} alignItems={'center'} spacing={3}>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"Country"} />
                                        <CustomTextField value={selectedCountry}  name={"country"} placeholder={"Country"} />
                                    </Grid>
                                    <Grid item>
                                        <CustomInputLabel required={true} label={"Currency"} />
                                        <CustomTextField value={selectedCurrency}  name={"currency"} placeholder={"Currency"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*    end of form */}
                        <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                            <Box component="div">
                                <Button sx={cancelButtonStyles} onClick={() => {
                                    navigate("/createBusiness")
                                    // localStorage.setItem('schoolTypeState',false)
                                    // remove SchoolTypeState from local storage
                                    localStorage.removeItem('schoolTypeState')

                                }}>
                                    Cancel
                                </Button>

                                <Button disabled={!formData.email ||!formData.schoolName ||!phoneNumber ||!formData.location ||!formData.schoolOwner || !selectedCountry ||!selectedCurrency}
                                    sx={doneButtonStyles} onClick={() => setShowSchoolDetails(true)}>
                                    Done
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    :
                    <Box sx={{ alignSelf: "center", width: "100%" }}>
                        {/*title*/}
                        <Box component="div" sx={{ display: "flex", width: "100%", justifyContent: "center", alignSelf: "center", marginY: 2 }}>
                            <Typography sx={{ alignSelf: "center", fontSize: "1rem", color: "#17ae7b", fontWeight: 700 }}>
                                School Summary
                            </Typography>
                        </Box>
                        {/*    end of title*/}


                        {/*begin of form*/}
                        <Box compnent="div" sx={{ display: "flex", width: "100%", justifyContent: "center", alignSelf: "center" }}>
                            {/*column one*/}
                            <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 2, width: "35%" }}>
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%", position: 'relative' }}>
                                    <Typography variant='P'>Your Logo</Typography>
                                    {
                                        !logoImg ?
                                            <>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    width: "198px",
                                                    height: "102px",
                                                    padding: "35px",
                                                    border: "dashed 2px #c2cdda",
                                                    position: 'relative',
                                                    cursor: 'pointer'
                                                }}
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                >
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        multiple="false"
                                                        onChange={handleFileInputChange}
                                                        style={{ display: "none" }}
                                                        ref={imageUploader}
                                                    />
                                                    <img style={{
                                                        width: "150px",
                                                        height: "90px",
                                                        position: "absolute",
                                                    }}
                                                        onClick={() => imageUploader.current.click()}
                                                        alt="Upload" src={img ? logoImg : icon} ref={uploadedImage} />
                                                </Box>
                                                {/* <Box sx={{ display:'flex', flexDirection:'column', position:'absolute', top: 50, left: 166, zIndex: 99, width:'30px' }}>
                                            <Button style={{ color:'#fff', backgroundColor:'#032541', width:'5%', height:'30px' }} onClick={() => imageUploader.current.click()}><EditIcon /></Button>
                                            <Button style={{ color:'#dc3545', width:'30px', height:'30px' }} onClick={() => setShowDeleteModal(true)}><DeleteIcon /></Button>
                                        </Box> */}
                                            </> :
                                            <Box sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                width: "198px",
                                                height: "102px",
                                                padding: "35px",
                                                border: "dashed 2px #c2cdda",
                                                position: 'relative',
                                                cursor: 'pointer'
                                            }}>
                                                <img src={img} style={{
                                                    width: "150px",
                                                    height: "90px",
                                                    // position: "absolute",backgroundColor:'#032541'
                                                }} alt="img" />
                                                <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: 30, left: 163, zIndex: 99, width: '30px' }}>
                                                    <Button style={{ color: '#032541', width: '5%', height: '30px' }} onClick={() => imageUploader.current.click()}><EditIcon /></Button>
                                                    <Button style={{ color: '#dc3545', width: '30px', height: '30px' }} onClick={() => setShowDeleteModal(true)}><DeleteIcon /></Button>
                                                </Box>
                                            </Box>
                                    }

                                </Box>
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        School Type
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {/* {formData.schoolType} */}
                                        {schoolTypeName}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        School Name
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {formData.schoolName}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Director
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {formData.schoolOwner}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {schoolTypeState.includes("University") ? "Semesters" : "Grades"}
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {defaultGrades.map((grade) => {
                                            return (
                                                <span>{grade.schoolGrades}, </span>
                                            )
                                        })}

                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                            </Box>
                            {/*end column onw*/}

                            {/*column two*/}
                            <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 2, width: "35%" }}>
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Email
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {formData.email}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Location
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {formData.location}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Country
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {selectedCountry}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>

                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Currency
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {selectedCurrency}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />

                                </Box>
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Typography sx={{ color: "#707070", fontSize: "0.875rem", marginY: 0.5 }}>
                                        Phone Number
                                    </Typography>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", marginY: 0.5 }}>
                                        {"+" + phoneNumber}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#707070", marginY: 1 }} />
                                </Box>


                            </Box>
                            {/*end column two*/}

                        </Box>
                        <Box component="div" sx={{ width: "86%", display: "flex", justifyContent: "end", marginTop: 1 }}>
                            <Box component="div">
                                <Button sx={
                                    {
                                        "width": "7.813rem",
                                        "height": "2.813rem",
                                        "padding": "12.5px 36.7px 12.5px 38.3px",
                                        "borderRadius": "4px",
                                        "border": "solid 1px #032541",
                                        "color": "#032541"
                                    }} onClick={() => setShowSchoolDetails(false)}>
                                    Edit
                                </Button>

                                <Button sx={doneButtonStyles} onClick={submitSchoolDetails}>
                                    Next
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                }
            </Box>
            {/* } */}
        </Box>
    )
}

export default SchoolDetailsForm;
