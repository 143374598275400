/* eslint-disable react-hooks/exhaustive-deps */
import {
  TextField,
  Stack,
  Grid,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ActiveTable from "./ActiveTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked, removeModule } from "../../../../features/actionSlice";
import ErrorIcon from "@mui/icons-material/Error";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "axios";
import { handleDeactivated } from "../../../../features/AlertSlice";
import { setSearch } from "../../../../features/searchSlice";
import Papa from 'papaparse';
import BusinessesDropdown2 from "./BusinessesDropdown2";
import { makeStyles } from "@mui/styles";
import BusinessesDropdown3 from "./BusinessesDropdown3";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {  clearSelectedDate2, handleSelectedDate2 } from "../../../../features/dateSlice";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function exportArrayToCSV(data, filename) {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Active() {
  // const navigate = useNavigate()
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(removeModule())
  },[])

  const [open, setOpen] = React.useState(false);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const businessId = useSelector((state) => state.DetailsOfbusiness.businessId);

  const handleDeactivate = () => {
    try {
      axios
        .post(baseUrl + "/api/deActivateBusiness", {
          businessId,
        })
        .then((response) => {
          if (response) {
            console.log("deactivated successfully");
            dispatch(handleDeactivated(true));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (clickedItem === "Deactivate") {
      handleOpenDialog();
    }
    dispatch(removeClicked());
  }, [clickedItem]);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  const reduxSearchBy = useSelector((state) => state.dropdownAction.searchBy7);
  const [searchBy, setSearchBy] = useState(reduxSearchBy);

  useEffect(() => {
    setSearchBy(reduxSearchBy);
  }, [reduxSearchBy]);

 

  let group = localStorage.getItem('group')
  let exportData =  useSelector(state => state.exportDetails.businesses)
  let partnerexportData = useSelector(state => state.exportDetails.businesses.map(({BusinessID ,BusinessName , PhoneNo ,Terminals  ,Partner ,salesPCode, salesPName,Region ,Branch ,DateCreated}) => ({ BusinessID ,BusinessName , PhoneNo ,Terminals  ,Partner ,salesPCode, salesPName,Region ,Branch ,DateCreated})));
  const handleExport = () => {
    exportArrayToCSV(group === 'Partner' ? partnerexportData: exportData, 'Businesses.csv');
  };

  const [dateValue, setDateValue] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [finalStartDate, setFinalStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [finalEndDate, setFinalEndDate] = useState(null);

  const dateChange = (e) => {
    setDateValue(e);
  };

  useEffect(() => {
    if (dateValue) {
      setStartDate(dateValue[0]);
      setEndDate(dateValue[1]);
    }
  }, [dateValue]);

  useEffect(() => {
    if (startDate) {
      const inputDate = new Date(startDate);

      // Get the components of the date
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
      const day = inputDate.getDate().toString().padStart(2, "0");
      const hours = inputDate.getHours().toString().padStart(2, "0");
      const minutes = inputDate.getMinutes().toString().padStart(2, "0");
      const seconds = inputDate.getSeconds().toString().padStart(2, "0");
      const milliseconds = inputDate
        .getMilliseconds()
        .toString()
        .padStart(3, "0");
      const offset = "+00:00";

      // Construct the formatted date string
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offset}`;

      console.log("formattedDate");
      console.log(formattedDate);
      setFinalStartDate(formattedDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      const inputDate = new Date(endDate);

      // Get the components of the date
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
      const day = inputDate.getDate().toString().padStart(2, "0");
      const hours = inputDate.getHours().toString().padStart(2, "0");
      const minutes = inputDate.getMinutes().toString().padStart(2, "0");
      const seconds = inputDate.getSeconds().toString().padStart(2, "0");
      const milliseconds = inputDate
        .getMilliseconds()
        .toString()
        .padStart(3, "0");
      const offset = "+00:00";

      // Construct the formatted date string
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offset}`;

      console.log("formattedDate2");
      console.log(formattedDate);
      setFinalEndDate(formattedDate);
    }
  }, [endDate]);

  useEffect(() => {
    if (finalStartDate && finalEndDate) {
      let obj = {
        start: finalStartDate,
        end: finalEndDate,
      };
      dispatch(handleSelectedDate2(obj));
    }
  }, [finalStartDate, finalEndDate]);

  useEffect(() => {
    dispatch(clearSelectedDate2());
  }, []);

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction="row" spacing={2}>
              <BusinessesDropdown3 />
              <TextField
              id="input-with-icon-textfield"
              label={`Search by ${searchBy}`}
              // label="Search by business name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
              className={classes.focusedTextField}
            />

            </Stack>
            
            <DateRangePicker
            onChange={dateChange}
            value={dateValue}
            clearIcon={null}
          />

            <BusinessesDropdown2 />

            <Button
              variant="outlined"
              sx={{
                bgcolor: "#f5f6f7",
                borderColor: "#f5f6f7",
                color: "#032541",
                ":hover": {
                  borderColor: "#032541",
                },
                textTransform: "none",
              }}
              onClick={handleExport}
            >
              Export
              <MoreVertIcon
                sx={{
                  color: "#032541",
                }}
              />
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ActiveTable />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Stack direction="row">
            <Stack
              width={"30%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <ErrorIcon
                sx={{
                  fontSize: "100px",
                  color: "#dc3545",
                }}
              />
            </Stack>
            <Stack width={"70%"}>
              <Typography
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="600"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Deactivate Business?
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#707070"
              >
                Selected Business will be deactivated...
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                {businessId}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#ffffff",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#032541",
              color: "#032541",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleDeactivate();
            }}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#dc3545",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#dc3545",
              color: "white",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default Active;
