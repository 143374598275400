import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from "@mui/material";
import CustomDropZone from "./CustomDropZone";
import ServiceCategoryForm from "./ServiceCategoryForm";
import ServiceForm from "./ServiceForm";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import CustomSelectField from "./CustomSelectField";
import { addParent } from "../../features/customerSlice";


const ParentGuardianForm = (props) => {
    const [addAnother, setAddAnother] = useState(false)
    const [formData, setFormData] = useState(
        {
            firstName: "",
            lastName: "",
            gender: "",
            email: "",
            mobileNumber: "",
            address: "",
            id_number: ""

        }
    )
    const [formDataTwo, setFormDataTwo] = useState(
        {
            firstName: "",
            lastName: "",
            gender: "",
            email: "",
            mobileNumber: "",
            address: "",
            id_number: ""
        }
    )
    const buttonStyles =
    {
        "minWidth": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    const cancelButtonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
        "border": "solid 1px #dc3545",
        "color": "#dc3545"
    }

    const doneButtonStyles = {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        "color": "white",
        "marginLeft": 2,
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    // Handle Change Two
    const handleInputChangeTwo = (e) => {
        setFormDataTwo({
            ...formDataTwo,
            [e.target.name]: e.target.value,
        });
    };


    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" }
    ]

    const dispatch = useDispatch();

    const [validEmail, setValidEmail] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberTwo, setPhoneNumberTwo] = useState('')

    const submit = () => {
        let newObject = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            paymentType: "Prepaid",
            phone: "+" + phoneNumber,
            amountReceived: 0,
            customerAddress: formData.address,
            businessId: "",
            customerType: "Individual",
            email: formData.email,
            gender: formData.gender,
            id_number: formData.id_number
        }
        dispatch(addParent(newObject));
        console.log('Parent data 1', newObject)
        setAddAnother(true)
        // props.setView("student")
    }
    const submitTwo = () => {
        let newObject2 = {
            firstName: formDataTwo.firstName,
            lastName: formDataTwo.lastName,
            paymentType: "Prepaid",
            phone: "+" + phoneNumberTwo,
            amountReceived: 0,
            customerAddress: formDataTwo.address,
            businessId: "",
            customerType: "Individual",
            email: formDataTwo.email,
            gender: formDataTwo.gender
        }
        dispatch(addParent(newObject2));
        console.log('Parent2 data', newObject2)
        setAddAnother(true)
        props.setView("student")
    }

    const validatePhoneNumber = (phoneNumber) => {
        return phoneNumber.trim().length === 12

    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };



    //validate phone

    const handlePhoneChange = (value, country, e, formattedValue) => {
        const isValid = validatePhoneNumber(value);
        setIsValidPhone(isValid);
        //setPhoneNumber(isValid ? value:'')
        if (isValid) {
            setPhoneNumber(value)
            e.target.blur();
        } else {
            setPhoneNumber(value)
        }
    };

    const handlePhoneNumbertwochange = (value, e) => {
        const isValid = validatePhoneNumber(value);
        setIsValidPhone(isValid);
        //setPhoneNumber(isValid ? value:'')
        if (isValid) {
            setPhoneNumberTwo(value)
            e.target.blur();
        } else {
            setPhoneNumberTwo(value)
        }
    }

    console.log(phoneNumber.length, 'yooo')

    useEffect(() => {
        if (formData.email != "") {
            let result = validateEmail(formData.email);
            setValidEmail(result);
            // console.log("email valid", result)
        } else {
            setValidEmail(true);
        }
    }, [formData.email]);


    return (
        <Box component="div" sx={{ display: "flex", justifyContent: "center", margin: 2, width: "100%" }}>
            {addAnother ? <>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <h4 style={{ fontSize: "16px", fontWeight: "600", color: "#707070", textAlign: "center" }}>Add Secondary Parent/Guardian (Optional)</h4>
                    <Box component="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                            <CustomInputLabel required={true} label={"First Name"} />
                            <CustomTextField value={formDataTwo.firstName} onChange={handleInputChangeTwo} name={"firstName"} placeholder={"First Name"} />

                            <CustomInputLabel required={true} label={"Gender"} />
                            <CustomSelectField value={formDataTwo.gender} onChange={handleInputChangeTwo} name={"gender"} placeholder={"Select Gender"} options={genderOptions} />
                            {/*<CustomTextField value={formData.gender} onChange={handleInputChangeTwo} name={"gender"} placeholder={"Select Gender"} />*/}

                            <CustomInputLabel required={true} label={"Email"} />
                            {!validEmail ?
                                <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                    Please enter a valid email
                                </Typography>
                                :
                                null
                            }
                            <CustomTextField value={formDataTwo.email} onChange={handleInputChangeTwo} name={"email"} placeholder={"Email"} />
                        </Box>


                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                            <CustomInputLabel required={true} label={"Last Name"} />
                            <CustomTextField value={formDataTwo.lastName} onChange={handleInputChangeTwo} name={"lastName"} placeholder={"Last Name"} />

                            <CustomInputLabel required={true} label={"Mobile Number"} />
                            <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                                {!isValidPhone ?
                                    <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                        Phone number should be 10 characters
                                    </Typography>
                                    :
                                    null
                                }
                                <PhoneInput inputStyle={{ height: '3.438rem' }} required country={"ke"} enableSearch={true} value={phoneNumberTwo} name={"mobileNumber"} onChange={handlePhoneNumbertwochange} />
                            </Box>
                            {/*<CustomTextField value={formData.mobileNumber} onChange={handleInputChangeTwo} name={"mobileNumber"} placeholder={"Mobile Number"} />*/}

                            <CustomInputLabel required={true} label={"Physical Address"} />
                            <CustomTextField value={formDataTwo.address} onChange={handleInputChangeTwo} name={"address"} placeholder={"Physical Address"} />
                        </Box>
                    </Box>

                    <Box component="div" sx={{ width: "95%", display: "flex", justifyContent: "end" }}>
                        <Box component="div">
                            <Button sx={{ ...buttonStyles, marginRight: 1, color: "red", '&:hover': { backgroundColor: "transparent", color: 'red' } }} onClick={() => props.setView("student")}>
                                Skip
                            </Button>
                            <Button sx={cancelButtonStyles} onClick={() => props.setView("initial")}>
                                Cancel
                            </Button>

                            <Button
                                sx={doneButtonStyles}
                                onClick={submitTwo}
                                disabled={!formDataTwo.firstName.trim()
                                    || !formDataTwo.lastName.trim()
                                    || !formDataTwo.gender.trim()
                                    || !formDataTwo.email.trim() ||
                                    (!phoneNumberTwo || phoneNumberTwo.trim().length !== 12)
                                    || !formDataTwo.address.trim()
                                }
                            >
                                Next
                            </Button>
                        </Box>

                    </Box>
                </Box>
            </> : <>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <h4 style={{ fontSize: "16px", fontWeight: "600", color: "#707070", textAlign: "center" }}>Add Primary Parent/Guardian <strong style={{ color: "#DC3545" }}> (Complusory)</strong> </h4>
                    <Box component="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                            <CustomInputLabel required={true} label={"First Name"} />
                            <CustomTextField value={formData.firstName} onChange={handleInputChange} name={"firstName"} placeholder={"First Name"} />

                            <CustomInputLabel required={true} label={"Gender"} />
                            <CustomSelectField value={formData.gender} onChange={handleInputChange} name={"gender"} placeholder={"Select Gender"} options={genderOptions} />
                            {/*<CustomTextField value={formData.gender} onChange={handleInputChange} name={"gender"} placeholder={"Select Gender"} />*/}

                            <CustomInputLabel required={true} label={"Email"} />
                            {!validEmail ?
                                <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                    Please enter a valid email
                                </Typography>
                                :
                                null
                            }
                            <CustomTextField value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                        </Box>


                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                            <CustomInputLabel required={true} label={"Last Name"} />
                            <CustomTextField value={formData.lastName} onChange={handleInputChange} name={"lastName"} placeholder={"Last Name"} />

                            <CustomInputLabel required={true} label={"Mobile Number"} />
                            <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                                {/* <PhoneInput inputStyle={{ height: '3.438rem' }} required country={"ke"} enableSearch={true} value={phoneNumber} name={"mobileNumber"} onChange={handlePhoneChange} />
                                {!isValidPhone && <p style={{ color: 'red' }}>Phone number must be 10 digits</p>} */}
                                {!isValidPhone ?
                                    <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                        Phone number should be 10 characters
                                    </Typography>
                                    :
                                    null
                                }
                                <PhoneInput inputStyle={{ height: '3.438rem' }} required country={"ke"} enableSearch={true} value={phoneNumber} name={"mobileNumber"} onChange={handlePhoneChange} />
                            </Box>
                            {/*<CustomTextField value={formData.mobileNumber} onChange={handleInputChange} name={"mobileNumber"} placeholder={"Mobile Number"} />*/}


                            <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                                <CustomInputLabel required={true} label={"ID Number"} />
                                <CustomTextField value={formData.id_number} onChange={handleInputChange} name={"id_number"} placeholder={"ID Number"} />
                            </Box>
                        </Box>


                    </Box>

                    <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                        <CustomInputLabel required={true} label={"Physical Address"} />
                        {/* <CustomTextField value={formData.address} onChange={handleInputChange} name={"address"} placeholder={"Physical Address"} /> */}
                        <TextField
                            name={"address"}
                            value={formData.address}
                            onChange={handleInputChange}
                            placeholder={"Physical Address"}
                            variant="outlined"
                            margin="dense"
                            sx={{ marginBottom: 2 }}
                            multiline
                            minRows={1}
                            InputProps={{
                                sx: {
                                    width: '51rem',
                                    fontSize: "0.875rem",
                                    "& fieldset": {
                                        borderColor: "#bec5d1"
                                    }
                                }
                            }}
                        />
                    </Box>


                    <Box component="div" sx={{ width: "95%", display: "flex", justifyContent: "end" }}>
                        <Box component="div">
                            <Button sx={cancelButtonStyles} onClick={() => props.setView("initial")}>
                                Cancel
                            </Button>

                            <Button
                                sx={doneButtonStyles}
                                onClick={submit}
                                disabled={
                                    !formData.firstName.trim() ||
                                    !formData.lastName.trim() ||
                                    !formData.gender.trim() ||
                                    !formData.email.trim() ||
                                    (!phoneNumber || phoneNumber.trim().length !== 12) ||
                                    !formData.address.trim()
                                }
                            >
                                Next
                            </Button>
                        </Box>

                    </Box>
                </Box>
            </>}


        </Box>
    )
}

export default ParentGuardianForm;
