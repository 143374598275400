import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Grid, Input, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import HttpComponent from "./MakeRequest";
import { useSelector, useDispatch, } from "react-redux";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { ArrowBack, CheckBox } from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { handleIsCardSetup, setCardStatus } from '../../features/paymentOptionsSlice';
import EditCard from './EditCard';


const baseUrl = process.env.REACT_APP_BASE_URL;


const CardSetup = ({setSuccessShow,setErrorShow}) => {
    const { cardPaymentData } = useSelector((store) => store.payment);
    const [merchantId, setMerchantId] = useState('')
    const [merchantKey, setMerchantKey] = useState('')
    const [selectedAccountType, setSelectedAccountType] = useState('')
    const [merchantSecret, setMerchantSecret] = useState('')
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setmessage] = useState('')
    const [ifError, setError] = useState(false)
    const dispatch = useDispatch();


    const [formState, setFormState] = useState(false)
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [showPassword1, setShowPassword1] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };


    // card status check
    const { cardStatus } = useSelector((store) => store.payment);

    

    // save configured card details
    const saveCardDetails = async () => {
        console.log('businessName',merchantId,merchantKey, merchantSecret)
        if (merchantId === ' ') {
            // alert('Kindly select the bankname')
            setOpenSnack(true)
            setError(true)
            setmessage('merchantId is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (merchantKey === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('merchantKey is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);

        }else if (selectedAccountType === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('accountType is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        }else if (merchantSecret === '') {
            setOpenSnack(true)
            setError(true)

            setmessage('merchantSecret is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else {
            try {
                const response = await fetch(`${baseUrl}/api/v1/business/card-merchant-config`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify({
                        merchantId,
                        merchantKey,
                        merchantSecretKey: merchantSecret,
                        accountType:selectedAccountType
                    }),
                })
                await response.json().then((data) => {
                    console.log('response', data)
                    setOpenSnack(true)
                    setError(false)
                    setmessage('Successfully added card details')
                    dispatch(setCardStatus(true))
                    dispatch(handleIsCardSetup(true));
                    // setTimeout(() => {
                    //     setOpenSnack(false);: merch
                    //     window.location.reload();
                    //     // setSuccessfullyPaid(true);
                    // }, 2000);
                })
            } catch (error) {
                console.log(error);
            }
        }
    }


    const buttonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }


    const changeFormState = () => {
        if (formState) {
            setFormState(false)

        } if (!formState) {
            setFormState(true)
        }

    }

    return (
        <>
            <Box component="div" sx={{ fontFamily: 'Poppins', width: "95%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop: "4.6%" }}>
                <div className="topHeader" style={{ display: "flex", marginTop: "15px", marginLeft: "16px" }}>
                    <ArrowBack onClick={changeFormState} style={{ fontSize: "30px", cursor: "pointer" }} />
                    {cardStatus === false ? <h6 className='ml-4 mt-1' style={{ fontSize: "16px", color: "#032541", fontWeight: "600", letterSpacing: "" }}>Card Setup</h6> : 
                    <h6 className='ml-4 mt-1' style={{ fontSize: "16px", color: "#032541", fontWeight: "600", letterSpacing: "" }}>Edit Card Details</h6>}
                </div>
                <Box component="form" sx={{ '& .MuiTextField-root': { margin: "20px", paddingTop: "10px" }, }} noValidate autoComplete='off' >
                    {
                        cardStatus === false ? <form>
                        <FormControl style={{ width:'100%' }}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Merchant ID</InputLabel>
                        <TextField style={{ width: "95%", marginTop: "30px"}}
                             id="outlined-multiline-flexible"
                             placeholder="Merchat ID"
                             value={merchantId}
                             onChange={(e) => setMerchantId(e.target.value)}
                             multiline
                             required
                         />
                        </FormControl>
                         <FormControl style={{ width: "100%", marginTop: '-15px'}}>
                             <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Merchant Key</InputLabel>
                             <OutlinedInput
                                 style={{ width: "95%", marginTop: "45px", marginLeft:'20px'}}
                                 placeholder='Key'
                                 id="outlined-adornment-password"
                                 type={showPassword ? 'text' : 'password'}
                                 value={merchantKey}
                                 onChange={(e) => setMerchantKey(e.target.value)}
                                 endAdornment={
                                     <InputAdornment position="end">
                                         <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={handleClickShowPassword}
                                             onMouseDown={handleMouseDownPassword}
                                             edge="end"
                                         >
                                             {showPassword ? <VisibilityOff /> : <Visibility />}
                                         </IconButton>
                                     </InputAdornment>
                                 }
                             />
                         </FormControl>
                         <FormControl style={{ width: "100%"}}>
                         <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Merchant Secrete Key</InputLabel>
                             <OutlinedInput
                                 style={{ width: "95%", marginTop: "45px",  marginLeft:"20px" }}
                                 id="outlined-adornment-password"
                                 placeholder='Secret Key'
                                 type={showPassword1 ? 'text' : 'password'}
                                 value={merchantSecret}
                                 onChange={(e) => setMerchantSecret(e.target.value)}
                                 endAdornment={
                                     <InputAdornment position="end">
                                         <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={handleClickShowPassword1}
                                             onMouseDown={handleMouseDownPassword1}
                                             edge="end"
                                         >
                                             {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                         </IconButton>
                                     </InputAdornment>
                                 }
                                 label="Password"
                             />
                         </FormControl>
                            <FormControl sx={{mt:2 ,mr:2, ml:2}} style={{display:'flex', flexDirection:'column'}}>
                                <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedAccountType}
                                    sx={{width:'100%'}}                                    label="Account Type"
                                    onChange={(e)=>setSelectedAccountType(e.target.value)}
                                >
                                    <MenuItem value={'pocketmoney'}>Pocket Money</MenuItem>
                                    <MenuItem value={'general'}>General</MenuItem>
                                </Select>
                            </FormControl>
 
                         <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: '20px', paddingBottom: '20px'}}>
                             <Box component="div" sx={{width:"95%", display:"flex", justifyContent:"end", marginTop: '30px'}}>
                                 <Box>
                                 <Button sx={{
                                ...buttonStyles,
                                 marginLeft:1,
                                 backgroundColor:" #032541",
                                 color:"white",
                                 '&:hover': {
                                     backgroundColor: '#032541',
                                     color: 'white'
                                 }
                                 }}
                                 onClick={saveCardDetails}
                                 >
                                 Save
                                 </Button>
                                 </Box>
                             </Box>
                         </div>
                     </form>
                    :
                        <EditCard cardPaymentData={cardPaymentData} setSuccessShow={setSuccessShow}  setErrorShow={setErrorShow} />
                    }
                    <Snackbar open={openSnack} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                        <Alert severity={ifError === true ? "error" : "success"} sx={{ width: "100%" }}>
                            <span>{message}</span>
                        </Alert>
                    </Snackbar>

                </Box>
            </Box>
        </>


    )
}
export default CardSetup;
