import React, {useState, useEffect} from 'react';
import {Box, Button, Typography} from "@mui/material";
import CustomDropZone from "./CustomDropZone";
import ServiceCategoryForm from "./ServiceCategoryForm";
import ServiceForm from "./ServiceForm";
import HttpComponent from "./MakeRequest";
import {useDispatch, useSelector} from "react-redux";
import {ErrorAlert} from "../snackBar Alerts/errorAlert";
import CustomTable from "./CustomTable";
import {SuccessAlert} from "../snackBar Alerts/successAlert";
import {addService, clearServicesToAdd, removeFromServicesToAdd} from "../../features/servicesSlice";
import warningImage from "./Images/warning-remove-icn.svg";
import ReusableModal from "./ReusableModal";
import {addCategoriesToAdd, removeFromCategoriesToAdd} from "../../features/businessCategorySlice";


const ServiceSetup = (props) =>{
    const dispatch = useDispatch();
    const [formView, setFormView] = useState("form"); // return this to inital
    const [successToast, setSuccessToast] = useState({state: false, message: ""})
    const [errorShow, setErrorShow] = useState({state: false, message: ""})
    const [tableData, setTableData] = useState([])
    const [indexToBeDeleted, setIndexToBeDeleted] = useState();
    const [fromUpload, setFromUpload] = useState(false);

     const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)

    const showUploaded = (newServices) => {
        let data = newServices.newRecords
        setSuccessToast({state: true, message:"Services uploaded successfully."})
        for(let i =0; i < data.length; i++){
            let newData ={
                serviceName:data[i].productName,
                categoryId:data[i].productCategory,
                amount:data[i].productPrice,
                description:data[i].productDescription,
                priceType:data[i].priceStatus,
            }
            dispatch(addService(newData))
        }
        setFromUpload(true)
        setFormView("Done")
    }

    const buttonStyles =
        {"minWidth":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }

    const createButton = {
        "width": "15.313rem",
        "height": "2.813rem",
        "borderRadius": "5px",
        "border": "solid 1px #002543",
        "backgroundColor": "#fff",
        "color":"#032541",
        '&:hover': {
            backgroundColor: '#fff',
            color: '#032541'
        }
    }


    const { servicesToAdd,categories} = useSelector((store) => store.services);
    const { userId ,X_Authorization } = useSelector((store) => store.user);

    // useEffect(() => {
    //     if(!servicesToAdd){
    //         setSuccessToast({state:true, message: "Service created successfully.."})
    //     }else{
    //         setFormView("done")
    //     }
    // }, []);

    const [buttonsDisabled, setButtonsDisabled] = useState(true)
    const isButtonsDisabled = () =>{
        if(servicesToAdd == null){
            console.log("disabled")
            setButtonsDisabled(true)
            return true
        }else{
            console.log("not disabled")
            setButtonsDisabled(false)
            return false
        }
    }

    useEffect(() => {
        isButtonsDisabled()
    }, [servicesToAdd]);


    const categoryIdMap = categories?.reduce((map, category) => {
        map[category.value] = category.label;
        return map;
    }, {});

    const [warningOpen, setWarningOpen] = useState(false);

    const columns = [
        { headerName: "Service Name", field: "serviceName", flex:1 },
        { headerName: "Service Category", field: "categoryId" ,flex:1,
            renderCell:params => (
                <Typography>
                    {categoryIdMap[params.value] || params.value}
                </Typography>
            )
        },
        { headerName: "Price Type", field: "priceType" ,flex:1},
        { headerName: "Amount", field: "amount" ,flex:1},

        {headerName: "Grade", field:"grade", flex:1},
        {headerName: "Term", field:"term", flex:1},
        { headerName: "Service Description", field: "description" ,flex:1},
        {
            field: 'Action',
            headerName: 'Action',
            renderCell: (params) => (
                <Button
                    sx={{
                        color:"#dc3545",
                        '&:hover': {
                            color: '#dc3545'
                        }
                    }}

                    onClick={() => {
                        const index = servicesToAdd.findIndex((service) => service.serviceName === params.row.serviceName);
                        setIndexToBeDeleted(index)
                        setWarningOpen(true);
                    }}
                >
                    Remove
                </Button>
            ),
        },
    ];
    const columnsCollege = [
        { headerName: "Service Name", field: "serviceName", flex:1 },
        { headerName: "Service Category", field: "categoryId" ,flex:1,
            renderCell:params => (
                <Typography>
                    {categoryIdMap[params.value] || params.value}
                </Typography>
            )
        },
        { headerName: "Price Type", field: "priceType" ,flex:1},
        { headerName: "Amount", field: "amount" ,flex:1},
        
        {headerName: "Course", field:"grade", flex:1},
        {headerName: "Semester", field:"term", flex:1},
        { headerName: "Service Description", field: "description" ,flex:1},
        {
            field: 'Action',
            headerName: 'Action',
            renderCell: (params) => (
                <Button
                    sx={{
                        color:"#dc3545",
                        '&:hover': {
                            color: '#dc3545'
                        }
                    }}

                    onClick={() => {
                        const index = servicesToAdd.findIndex((service) => service.serviceName === params.row.serviceName);
                        setIndexToBeDeleted(index)
                        setWarningOpen(true);
                    }}
                >
                    Remove
                </Button>
            ),
        },
    ];

    const handleWarningClose = () =>{
        setWarningOpen(false);
    }

    const doRemove = () =>{
        dispatch(removeFromServicesToAdd({index:indexToBeDeleted}))
        handleWarningClose()
    }


    useEffect(() => {
        const dataWithGridId = servicesToAdd?.map((row, index) => ({
            ...row,
            gridId: `${index}-${row.id}`
        }));
        setTableData(dataWithGridId)
    }, [servicesToAdd]);

    const submit = () =>{
        HttpComponent({
            method: 'POST',
            url: '/api/add_services',
            body: {services:servicesToAdd},
            token: X_Authorization
        }).then((data)=>{
           // console.log("here store is data",data);
            if(data.status === 201){
                props.next()
                dispatch(clearServicesToAdd())
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
        })
    }

    const uploadNext = () =>{
        props.next()
        dispatch(clearServicesToAdd())
    }

    //console.log(formView , 'formView >>>>>>>>')


    return(
        <Box component="div" sx={{display:"flex", justifyContent:"center", margin:2, width:"100%"}}>
            <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                <SuccessAlert  vertical="top"
                               horizontal="right"
                               onClose={()=>setSuccessToast({...successToast, state:false})}
                               open={successToast.state}
                               message={successToast.message} />
                <ErrorAlert
                    vertical="top"
                    horizontal="right"
                    onClose={()=>setErrorShow({...errorShow, state:false})}
                    open={errorShow.state}
                    message={errorShow.message}/>
                {formView === "initial" ?
                    //    default view
                    <>
                        <Box sx={{alignSelf:"center", marginY:2}}>
                            <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                Add at least 1 service
                            </Typography>
                        </Box>

                        <Box sx={{alignSelf:"center"}}>
                            <CustomDropZone
                                setShowErrorToast={setErrorShow}
                                setShowSuccessToast={setSuccessToast}
                                url={"/api/upload_products"}
                                successAction={showUploaded}
                            />

                        </Box>


                        <Box component="div" sx={{alignSelf:"center", marginY:2}}>
                            <Button sx={createButton} onClick={()=>setFormView("form")}>
                                Create Service
                            </Button>
                        </Box>

                    </>
                    : formView === "form" ?
                        //    form view here
                        <>
                            <Box sx={{alignSelf:"center", marginY:2}}>
                                <Box sx={{marginY:2, textAlign:"center",}}>
                                    <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                        Create a Service
                                    </Typography>
                                </Box>

                                <ServiceForm setView={setFormView}/>
                            </Box>


                        </>
                        :
                        <>
                            {/*    custom data grid will go*/}
                            <Box component={"div"} sx={{display:"flex", width:"100%", justifyContent:"space-between", marginTop:"2%"}}>
                                <Box component="div" sx={{marginLeft:"5.8%"}}>
                                    <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                        List Of Services
                                    </Typography>
                                </Box>
                                <Box sx={{display:"flex", marginRight:"5%"}}>
                                    <Button sx={{
                                        ...buttonStyles,
                                        marginLeft:1,
                                        backgroundColor:" #032541",
                                        color:"white",
                                        '&:hover': {
                                            backgroundColor: '#032541',
                                            color: 'white'
                                        }
                                    }}
                                            onClick={()=>setFormView("form")}
                                    >
                                        Create Service
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{alignSelf:"center", width:"90%", marginTop:"2%"}}>
                                <CustomTable data={tableData} columns={schoolTypeName?.includes("University") ? columnsCollege : columns} rowsPerPage={5}/>
                            </Box>
                            <ReusableModal open={warningOpen} onClose={handleWarningClose} width="34.688rem">
                                <Box sx={{display:"flex", justifyContent:"center", padding:"3.125rem"}}>
                                    <Box sx={{alignSelf:"center",display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                        <Box sx={{display:"flex"}}>
                                            <img style={{paddingRight:"2.5rem"}} src={warningImage} alt="Warning"/>
                                            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                <Typography sx={{color:"#032541", fontWeight:600}}>
                                                    Remove Service ?
                                                </Typography>
                                                <Typography sx={{color:"#323d52", fontSize:"0.875rem"}}>
                                                    Service will be deleted. <br/> This action cannot be undone.
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box sx={{display:"flex", justifyContent:"center", gap:2, marginTop:5}}>
                                            <Button sx={{
                                                backgroundColor:"transparent",
                                                border:"1px solid #002543",
                                                color:"#002543",
                                                width:"7.813rem",
                                                height:"2.813rem",
                                                '&:hover': {
                                                    backgroundColor:"transparent",
                                                    color:"#002543",
                                                }
                                            }}
                                                    onClick={handleWarningClose}
                                            >
                                                Cancel
                                            </Button>
                                            <Button sx={{
                                                backgroundColor:"#dc3545",
                                                color:"#ffffff",
                                                width:"7.813rem",
                                                height:"2.813rem",
                                                '&:hover': {
                                                    backgroundColor:"#dc3545",
                                                    color:"#ffffff",
                                                }
                                            }}
                                                    onClick={doRemove}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </ReusableModal>
                            {!fromUpload ?
                                <Box component="div" sx={{display: "flex", width: "95%", justifyContent: "end"}}>
                                    <Button sx={{
                                        "width": "7.813rem",
                                        "height": "2.813rem",
                                        backgroundColor: "#17ae7b",
                                        color: "white",
                                        '&:hover': {
                                            backgroundColor: '#17ae7b',
                                            color: "white",
                                        }
                                    }}
                                            disabled={buttonsDisabled}
                                            onClick={submit}
                                    >
                                        Next
                                    </Button>
                                </Box>
                                :
                                <Box component="div" sx={{display: "flex", width: "95%", justifyContent: "end"}}>
                                    <Button sx={{
                                        "width": "7.813rem",
                                        "height": "2.813rem",
                                        backgroundColor: "#17ae7b",
                                        color: "white",
                                        '&:hover': {
                                            backgroundColor: '#17ae7b',
                                            color: "white",
                                        }
                                    }}
                                            onClick={uploadNext}
                                    >
                                        Next
                                    </Button>
                                </Box>
                            }
                        </>
                }
            </Box>
        </Box>
    )
}

export default ServiceSetup;
