import { Box, Breadcrumbs, Button, Grid, Tab, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { alpha, styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomTable from "../../School/CustomTable";
import HttpComponent from "../../School/MakeRequest";
import { useNavigate } from "react-router-dom";
import AddPayeeForm from "./addPayeeForm";
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import EditForm from "./editPayeeform";
import RaisePaymentOtherPayments from "./raisePaymentPayee";
import PreviewRaisePaymentOtherPayments from "./previewOtherPayment";
import AuthecateUser from "../../paymentmodule/autheticatedComponent";
import UploadFileFT from "./uploadFileFT";
import CustomSearchInput from "../../School/CustomSearchInput";
import ExportMenu from "../../School/ExportMenu";
import DateFormatter from "../../../utils/dateFormatter";
import { DataGrid } from "@mui/x-data-grid";
import { CustomizedDate } from "../../customerAccounts/customerComponents/customDate";
import CustomSelectField from "../../School/CustomSelectField";




const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(0),
  fontSize: "16px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Other Payments</Typography>
]
const Payees = () => {
    const [tabValue, setTabValue] = useState('Active')
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const [editForm, setEditForm] = useState(false)
    const getRowId = (row) => {
        return row?._id || row?.id || row?.gridId || Math.floor(Math.random() * 2000000)
    }

    const navigate = useNavigate()
    const queryparams = decodeURIComponent(window.location.search);
    const [searchValue ,setSearchValue] = useState('')
    const stateUrl = queryparams.slice(1)
    const [addPayeeForm, setAddPayeeForm] = useState(false)
    const [dataGridPageSize, setPageSize] = useState(10);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [pageState, setPageState] = useState({
        isLoading: false,
        total: 0,
        data: [],
        page: 1,
        pageSize: dataGridPageSize
    })
    const [payeeId, setPayeeId] = useState('')
    const [items, setItems] = useState([])

    // filters
    const [transactionType, setTransactionType] = useState([]);

    const [days, setDays] = useState([
      { value: "Default", label: "Default" },
      { value: "Today", label: "Today" },
      { value: "Yesterday", label: "Yesterday" },
      { value: "Last 7 Days", label: "Last 7 Days" },
      { value: "This Month", label: "This Month" },
      { value: "Last Month", label: "Last Month" },
      { value: "Custom Range", label: "Custom Range" },
    ]);
  
    const [handleDaysChange, setHandleDaysChange] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
  
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
  
  
    useEffect(() => {
      switch (handleDaysChange) {
        case "Today":
          const today = new Date();
          setStartDate(today);
          setEndDate(today);
          break;
        case "Yesterday":
          let date = new Date();
          const yeseterday = new Date(date.setDate(date.getDate() - 1))
          setStartDate(yeseterday);
          setEndDate(yeseterday);
          break;
        case "Last 7 days":
          function Last7Days() {
            var result = [];
            for (var i = 0; i < 7; i++) {
              var d = new Date();
              result.push(d.setDate(d.getDate() - i));
            }
            const lastSevenFirst = new Date(result[0]);
            const lastSevenLast = new Date(result[result.length - 1])
  
            setStartDate(lastSevenLast);
            setEndDate(lastSevenFirst);
          }
          Last7Days();
          break;
        case "This Month":
          const date1 = new Date();
          const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
          const currentDay = new Date()
          setStartDate(firstDay);
          setEndDate(currentDay);
  
          break;
        case "Last Month":
          const date2 = new Date();
          const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
          const lastDayLastMonth = new Date(date2.setDate(1))
          setStartDate(firstDayLastMonth);
          setEndDate(lastDayLastMonth);
          break;
        case "Default":
          setStartDate("");
          setEndDate("");
          break;
        case "Custom Range":
          setStartDate(dateFrom);
          setEndDate(dateTo);
          break;
        default:
          return;
      }
    }, [handleDaysChange, dateFrom, dateTo]);

    // gett payees
    const getPayees = () => {
        setPageState((old) => ({ ...old, isLoading: true }));
        let url = ''
        if (tabValue === 'Active') {
            url = `/api/v1/payee/all?page=${pageState.page}&limit=${dataGridPageSize}&status=Active&search=${searchValue}`
        } else if (tabValue === 'Suspended') {
            // }/api/v1/payee/all?page=1&limit=10&status=Suspended
            url = `/api/v1/payee/all?page=${pageState.page}&limit=${dataGridPageSize}&status=Suspended&search=${searchValue}`
        }

        console.log(url, ' url')

        try {
            HttpComponent({
                method: 'GET',
                url: url,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, ' data.response.data')
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })


                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getPayees()
    }, [tabValue, pageState.page, dataGridPageSize, stateUrl ,searchValue])

    const SuspendActiavtePayee = (id) => {
        // console.log(id, ' payeeId')
        let status = ''
        if (tabValue === 'Active') {
            status = 'Suspended'
        } else if (tabValue === 'Suspended') {
            status = 'Active'
        }
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/payee/update_status`,
                token: localStorage.getItem('X-Authorization'),
                body: {
                    payeeId: id,
                    status: status
                }
            }).then((data) => {
                console.log(data, ' data.response.data')
                if (data?.status === 200 || data?.status === 201) {
                    setSuccessShow({ ...successShow, state: true, message: data?.response?.message })
                    getPayees()

                } else {
                    setErrorShow({ ...errorShow, state: true, message: data?.response?.error })
                }

            })
        } catch (error) {

        }
    }
    const showEditForm = (id) => {
        setEditForm(true)
        setPayeeId(id)
    }
    const columns = [
        {
            field: "name", headerName: "Name", flex: 1, renderHeader: (params) => {
                return (
                    <span style={{ display: "flex", fontWeight: "900", justifyContent: "flex-start" }}>
                        Name
                    </span>
                )
            },
            renderCell: (params) => {
                return (
                    <span style={{ display: "flex", justifyContent: "flex-start" }}>
                        {params.row.firstName + " " + params.row.lastName}
                    </span>
                )

            },
        },
        {
            field: "phoneNumber", headerName: "Phone Number", flex: 1,
            renderHeader: (params) => {
                return (
                    <span style={{ display: "flex", fontWeight: "900", justifyContent: "flex-start" }}>
                        Phone Number
                    </span>
                )
            },
        },
        {
            field: "email", headerName: "Email Address", flex: 1,
            renderHeader: (params) => {
                return (
                    <span style={{ display:"flex", fontWeight:"900", justifyContent:"flex-start" }}>
                        Email Address
                    </span>
                )
            },
        },

        // { field: "email", headerName: "Email", flex: 1 },
        {
            field: "action", headerName: "Action", flex: 1, headerAlign: 'center', align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        {tabValue === 'Active' ?
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#032541", cursor: "pointer" }}><EditIcon onClick={() => showEditForm(params?.row?._id)} /></Typography>
                                <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#dc3545", marginLeft: "20px", cursor: "pointer" }} onClick={() => SuspendActiavtePayee(params?.row?._id)}><PersonOffIcon /></Typography>

                            </Box>
                            : tabValue === 'Suspended' ? <>
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                                    <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#2E7D32", cursor: "pointer" }} onClick={() => SuspendActiavtePayee(params?.row?._id)}><RestoreIcon /></Typography>

                                </Box>
                            </> : <>
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                                    <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#032541", cursor: "pointer" }}><EditIcon /></Typography>
                                    <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#dc3545", cursor: "pointer" }}><PersonOffIcon /></Typography>

                                </Box>
                            </>}
                    </>

                )

            },
        }


    ]
    // export data
    const fileData = pageState?.data.map((parent) => {
        return {
            "Name": parent?.firstName + " " + parent?.lastName,
            "Phone No": parent?.phoneNumber,
            "Email Address": parent?.email,
            "Bank Name": parent?.bankName,
            "Account No": parent?.accountNumber,
            "Date Created": DateFormatter(parent?.createdAt)
        };
    });

    const fileDataPDF = pageState.data.map((parent) => [
        parent?.firstName + " " + parent?.lastName,
        parent?.phoneNumber,
        parent?.email,
        parent?.bankName,
        parent?.accountNumber,
        DateFormatter(parent?.createdAt),
    ]);


    const csvColumns = [
        { label: "Name", key: "Name" },
        { label: "Phone No", key: "Phone No" },
        { label: "Email Address", key: "Email Address" },
        { label: "Bank Name", key: "Bank Name" },
        { label: "Account No", key: "Account No" },
        { label: "Date Created", key: "Date Created" },
    ];

    const fileHeaders = [[
        "Name",
        "Phone No",
        "Email Address",
        "Bank Name",
        "Account No",
        "Date Created"
    ]]
    const showAddPayeeForm = () => {
        navigate('/payables/otherpayments?addPayeeForm')
    }
    const raisePayment = () => {
        navigate('/payables/otherpayments?raisePayment')
    }
    const [isAuthecated, setIsAuthecated] = useState(false)
    const checkAuth = () => {
        if (isAuthecated === false && stateUrl === 'raisePayment') {
            navigate('/payables/otherpayments?autheticate')
        }else if(isAuthecated === true && stateUrl === 'autheticate'){
            navigate('/payables/otherpayments?raisePayment')
        }else if(isAuthecated === true && stateUrl){
            navigate(`/payables/otherpayments?${stateUrl}`)
        }
    }
    useEffect(() => {
        checkAuth()
    }, [stateUrl])

    const handleIsAuthecated = (childData) => {
        let state = childData.state;
        console.log(state, 'statestatestate');
        
        setIsAuthecated(state)
        checkAuth()
    }
    const [file, setFile] = useState(null)

    const getFile = (childData) => {
        let file = childData.file;
        console.log(file, 'filefilefile');
        setFile(file)
    }



    return (
        <Grid >
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            {stateUrl === 'addPayeeForm' ? <AddPayeeForm />
                : editForm ? <EditForm payeeId={payeeId} />

                 : stateUrl === 'raisePayment' && isAuthecated   ? <RaisePaymentOtherPayments  getFile ={getFile}/> 
                 :stateUrl ===  'autheticate' ? <AuthecateUser handleIsAuthecated={handleIsAuthecated} />
                 :
                    stateUrl === 'preview' ? <PreviewRaisePaymentOtherPayments items={items} file={file} /> :
                        <Grid >
                            <Grid item mt={1}>
                                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Other Payments</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Grid>

                            <TabContext value={tabValue}>
                                <Grid container width="100%" sx={{ display: 'flex', justifyContent: 'space-between', padding: "5px 20px" }}>

                                    <Grid item>
                                        <Grid item>

                                            <AntTabs onChange={handleChange} sx={{ display: 'flex' }}>
                                                <AntTab label="Active" value="Active" />
                                                <AntTab label="Suspended" value="Suspended" style={{ marginLeft: '20px' }} />
                                            </AntTabs>

                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid item>
                                            <Button variant="contained" style={{ backgroundColor: "#fff", color: "#032541", height: "45px", width: "175px", border: "#032541 solid 1px", marginLeft: "10px", fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600 }} onClick={() => showAddPayeeForm()}>Add Payee</Button>
                                            <Button variant="contained" style={{ backgroundColor: "#032541", height: "45px", width: "155px", color: "#fff", marginLeft: "10px", fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600 }} onClick={() => raisePayment()} >Raise Payment</Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                        <TabPanel value="Active" >
                                            <Grid item width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                                <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search Payees'} />
                                                <Grid item display={"flex"} gap={"10px"} alignItems={"center"}>
                                                    {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                                                    {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                                                </Grid>
                                                <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={fileData} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`} />
                                            </Grid>
                                            <DataGrid
                                                // components={{ NoRowsOverlay: NoRowsOverlay }}
                                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                                rowCount={pageState.total}
                                                loading={pageState.isLoading}
                                                pagination
                                                page={pageState.page - 1}
                                                pageSize={dataGridPageSize}
                                                paginationMode="server"
                                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                columns={columns}
                                                rows={pageState.data}
                                                getRowId={getRowId}
                                            />
                                        </TabPanel>
                                        <TabPanel value="Suspended" >
                                            <Grid item width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                                <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search Payees'} />
                                                <Grid item display={"flex"} gap={"10px"} alignItems={"center"}>
                                                    {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                                                    {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                                                </Grid>
                                                <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={fileData} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`} />
                                            </Grid>
                                            <CustomTable data={pageState?.data}
                                                columns={columns}
                                                loading={pageState?.isLoading}
                                                rowsPerPage={pageState?.pageSize}
                                                count={pageState?.total}
                                            />
                                        </TabPanel>
                            </TabContext>
                        </Grid>}

        </Grid>
    )
}

export default Payees