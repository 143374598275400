import React, { useState, useEffect } from "react";
import CustomDataGrid from "../../products/stockComponents/customDataGrid";
import ReportFunctions from "../reportsFunctions";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import DateFormatter from "../../../utils/dateFormatter";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Exports } from "../../customerAccounts/customerComponents/exports";
import { CustomDate } from "../../customerAccounts/customerComponents/customDate";

const Transactions = (props) => {

  const resportMethods = new ReportFunctions();
  const navigate = useNavigate();
  const setExportData = props.setExportData;
  const setCsvExport = props.setCsvExport;
  
  let localCurrency = localStorage.getItem('localCurrency')
  if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
    localCurrency = 'KES'
  }
  
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const exportData = props.exportData
  const tabvalue = props.tabvalue
  const csvExport = props.csvExport
  const [openRange , setOpenRange] = useState(false)


  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });


  useEffect(() => {
    // pdf exports;
    const pdfData = pageState?.data?.map?.((transaction) => [transaction?.transactionID, transaction?.businessNo, transaction?.businessName, transaction?.transactionType, DateFormatter(transaction?.transtime), DateFormatter(transaction?.uploadTime), CurrencyFormatter(transaction?.transamount, localCurrency)]);
    const pdfHeaders = [["TXID", "Business No", "Business Name", "Type", "Time", "Upload", "Amount"]];
    setExportData({ headers: pdfHeaders, data: pdfData });

    // csv exports;
    const csvData = pageState?.data?.map((customer) => {
      return {
        "TXID": customer?.transactionID,
        "Business No": customer?.businessNo,
        "Business Name": customer?.businessName,
        "Type": customer?.transactionType,
        "Time": DateFormatter(customer?.transtime),
        "Upload": DateFormatter(customer?.uploadTime),
        "Amount": CurrencyFormatter(customer?.transamount, localCurrency),
      };
    });

    // csv headers;
    const csvHeaders = [
      { label: "TXID", key: "TXID" },
      { label: "Business No", key: "Business No" },
      { label: "Business Name", key: "Business Name" },
      { label: "Type", key: "Type" },
      { label: "Time", key: "Time" },
      { label: "Upload", key: "Upload" },
      { label: "Amount", key: "Amount" },
    ];

    setCsvExport({ headers: csvHeaders, data: csvData });
  }, [pageState.data, pageState.pageSize, dataGridPageSize ,startDate ,endDate]);

  const setStartAndEndDates = (e) => {
    switch (e.target.value) {
      case "Today":
        const today = new Date().toLocaleDateString();
        setStartDate(today);
        setEndDate(today);
        break;
      case "Yesterday":
        let date = new Date();
        const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
        // console.log(yeseterday);
        setStartDate(yeseterday);
        setEndDate(yeseterday);
        break;
      case "Last 7 days":
        function Last7Days() {
          var result = [];
          for (var i = 0; i < 7; i++) {
            var d = new Date();
            result.push(d.setDate(d.getDate() - i));
          }
          const lastSevenFirst = new Date(result[0]).toLocaleDateString();
          const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

          setStartDate(lastSevenLast);
          setEndDate(lastSevenFirst);
        }
        Last7Days();
        break;
      case "This Month":
        const date1 = new Date();
        const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
        const currentDay = new Date().toLocaleDateString();
        setStartDate(firstDay);
        setEndDate(currentDay);

        break;
      case "Last Month":
        const date2 = new Date();
        const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
        const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
        setStartDate(firstDayLastMonth);
        setEndDate(lastDayLastMonth);
        break;
      default:
        return e.target.value;
    }
  };

  useEffect(() => {
    resportMethods.getAllTransaction(pageState?.page, dataGridPageSize, startDate, endDate).then((data) => {

      console.log('data?.response?.count', data);
      if (data?.status === 200 || data?.status === 201) {
        setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count, isLoading: false });
      }
    })
  }, [pageState?.page, dataGridPageSize , endDate ,startDate])




  const columns = [
    {
      field: "transactionID",
      headerName: "TXID",
      flex: 1,
      filter: "text",
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"TXID"}
        </span>
      ),
      renderCell: (params) => {
        return (
          <span style={{ color: "#dc3545", cursor: "pointer" }} onClick={() => navigate(`/detailedbill/${params?.row?.transactionID}`)}>
            {" "}
            {params?.row?.transactionID}{" "}
          </span>
        );
      },
    },
    {
      field: "businessNo",
      headerName: "Business No",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"Business No"}
        </span>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"Business Name"}
        </span>
      ),
    },
    {
      field: "transactionType",
      headerName: "Type",
      flex: 1,
      filter: "text",
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"Type"}
        </span>
      ),
    },
    {
      field: "transtime",
      headerName: "Time",
      flex: 1,
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"Time"}
        </span>
      ),
      width: 200,
      valueGetter: (params) => {
        const transTime = params.row.transtime;
        return moment(transTime).tz("Africa/Nairobi").format("llll");
      },
    },
    {
      field: "uploadTime",
      headerName: "Upload Time",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"Upload Time"}
        </span>
      ),
      flex: 1,
      width: 200,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return moment(params.row.uploadTime).format("llll");
      },
    },
    {
      field: "customerFirstName",
      headerName: "Name",
      headerAlign: "left",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"Name"}
        </span>
      ),
      align: "left",
      flex: 1,
    },
    {
      field: "customerPhone",
      headerName: "Phone",
      flex: 1,
      width: 200,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"Phone"}
        </span>
      ),
      valueGetter: (params) => {
        const phone = params.row.customerPhone || "";
        return phone.replace(/(\d{5})\d{3}(\d{4})/, "$1***$2");
      },
    },
    {
      field: "transamount",
      flex: 1,
      headerName: "Amount",
      type: "number",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"Amount"}
        </span>
      ),
      valueGetter: (params) => {
        const amount = params.row.transamount || 0;
        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: localCurrency,
        }).format(amount);
      },
    },
    {
      field: "serialNo",
      headerName: "Serial",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#032541",
          }}
        >
          {"Serial"}
        </span>
      ),
    },
  ];


  const transRows = pageState?.data?.map((trans) => ({
    id: trans?._id,
    transactionID: trans?.transactionID,
    businessNo: trans?.businessNo,
    businessName: trans?.businessName,
    transactionType: trans?.transactionType,
    transtime: trans?.transtime,
    uploadTime: trans?.uploadTime,
    customerFirstName: trans?.customerFirstName,
    customerPhone: trans?.customerPhone,
    serialNo: trans?.serialNo,
    transamount: trans?.transamount

  }))
  return (
    <Grid container direction={'column'} display={'flex'}>
      <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Grid item display={'flex'} alignItems={'center'}>
            <FormControl style={{ width: "200px" }}>
              <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Date Range"
                onChange={setStartAndEndDates}
              >
                <MenuItem value={'Today'}>Today</MenuItem>
                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                <MenuItem value={'Last 7 days'}>Last 7 days</MenuItem>
                <MenuItem value={'This Month'}>This Month</MenuItem>
                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                <MenuItem value={'Custom Range'} onClick={()=>setOpenRange(true)}>Custom Range</MenuItem>
              </Select>
            </FormControl>
             {openRange === true ? <CustomDate setDateFrom={setStartDate} setDateTo={setEndDate} />:null }
            
          </Grid>
          <Grid item>
            <Exports exportData={exportData} activeTabs={tabvalue} csvExport={csvExport}  />
          </Grid>
        </Grid>
      </Grid>
      <Grid item mt={2}>
        <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={columns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={transRows} />
      </Grid>
    </Grid>
  );
};

export default Transactions;
