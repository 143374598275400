import { Grid ,Typography ,Breadcrumbs  } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom'
import CustomDataGrid from '../../../products/stockComponents/customDataGrid';
import { useEffect, useState } from 'react';
import HttpComponent from '../../../School/MakeRequest';
import DateFormatter from '../../../../utils/dateFormatter';
// breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Pocket Money</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Student Wallet Balance</Typography>
]

let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
}

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? "KES" : localCurrency, }).format(value);

export default function StudentWalleteReportsAdminDrillDown() {
    const navigate = useNavigate()
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
      // get query params!;
      const queryParams = decodeURIComponent(window.location.search);
      const queryParamValue = queryParams?.slice(1);
      const [searchvalue ,admissionNumber , businessNumber] = queryParamValue.split("?")
      const [studentName ,setStudentName] = useState('')

    // columns

    const studentBalanceCols = [
        { field: "receiptNo", headerName: "RRN/Receipt No", flex: 1 },
        { field: "transactionType", headerName: "Transaction Type", flex: 1 },
        { field: 'source', headerName: 'Source', flex: 1 },
        { field: 'createdAt', headerName: 'Date & Time', flex: 1 },
        { field: 'transAmount', headerName: 'Total Amount', flex: 1 }
    ]

    // fetch data for drilldown ---> student transactions

    function handleGetStudentTransactionList(){
        try {
            HttpComponent({
                method:'GET',
                url:`/api/getZedPayItWalletReports?studentNumber=${admissionNumber}&businessId=${businessNumber}`,
                token:localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setStudentName(data?.response?.studentName)
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }
            })
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        handleGetStudentTransactionList()
    },[admissionNumber ,businessNumber])

    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        receiptNo: config?.receiptNo,
        transactionType: config?.transactionType,
        source: config?.source,
        createdAt: DateFormatter(config?.createdAt),
        transAmount: numberFormat(config?.transAmount),
    }))


    


    return (
        <Grid container direction={'column'}>
              <Grid item mt={2} display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "20px", color: "#032541" }}>{admissionNumber} {studentName}</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={studentBalanceCols} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}