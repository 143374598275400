import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import ScannerImg from "../../images/qr-scanner-icn.png"
import { useNavigate } from "react-router-dom";
import welcomeEmoji from '../../common/images/welcomeEmoji.svg'
import { clearAdditions } from "../../features/customerSlice";
import { useDispatch, useSelector } from "react-redux";
import { handlePageReload } from "../../features/autorefreshonpageload";
import { QRcodeScanner } from "./qrCodeScanner";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HttpComponent from "../School/MakeRequest";
import { set } from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";
import DateFormatter from "../../utils/dateFormatter";


export default function NewUserDashboard() {

	const navigate = useNavigate()
	const username = localStorage.getItem('username')
	const dispatch = useDispatch();
	const theme = useTheme();
	const [dataGridPageSize, setPageSize] = useState(10);
	const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
	const isMuiMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { isPageLoaded } = useSelector((store) => store.pageload);

	function getTransactionsForStudent() {
		setPageState({ ...pageState, isLoading: true })
		try {
			HttpComponent({
				method: 'GET',
				url: `/api/v1/getstudentTransactions`,
				token: localStorage.getItem('X-Authorization'),
			}).then((data) => {
				if (data?.status === 200) {
					setPageState({ ...pageState, isLoading: false, data: data?.response?.data, count: data?.response?.count })
				}
			})

		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		getTransactionsForStudent()
	}, [])


	//const handleForceUpdate = useForceUpdate();
	// run auto refresh 
	const autoRefreshOnPageload = () => {
		setTimeout(() => {
			window.location.reload(false)
		}, 1000);

		dispatch(handlePageReload())
	}
	useEffect(() => {
		if (isPageLoaded === false) {
			autoRefreshOnPageload()
		}
	}, [])
	const [isOpenQR, setIsQrOpen] = useState(false)
	const closeQr = () => {
		setIsQrOpen(false)
	}
	const BioColumns = [
		{ headerName: "Business Name", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Payment To</strong>) } },
		{ headerName: "Transaction ID", field: 'transactionID', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Transaction ID</strong>) } },
		{ headerName: "Transaction Time", field: 'createdAt', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Transtime</strong>) } ,renderCell:(params)=><Typography sx={{fontSize:"12px"}}>{DateFormatter(params?.row?.createdAt)}</Typography>},
	]

	return (
		<div style={{ fontFamily: 'Poppins', width: "100%", display: "flex", flexDirection: 'column', justifyContent: 'center' }}>

			{/*Emoji*/}
			<Grid container display={'flex'} alignContent={'center'} justifyContent={'center'} style={{ marginTop: isMuiMobile ? '5px' : '15px' }}>
				<Grid item>
					<img src={welcomeEmoji} alt='welcome emoji' />
				</Grid>

			</Grid>
			{/*Welcome User*/}
			<Grid container justifyContent={'center'} style={{ marginTop: '30px' }}>
				<Grid item style={{ color: '#707070', fontSize: '25px', fontWeight: 500 }} >
					<span>Welcome <span>{username}</span></span>
				</Grid>
			</Grid>


			{/*Welcome Statement*/}
			<Grid container justifyContent={'center'} style={{ marginTop: '15px', alignText: 'center', textAlign: 'center' }} alignContent={'center'}>
				<Grid item style={{ color: '#032541', fontSize: '25px', fontWeight: 600, width: '600px' }}>
					<span>We are here to help you create a business and accept payments easily.</span>
				</Grid>
			</Grid>

			{/*Post Welcome Statement*/}
			<Grid container justifyContent={'center'} style={{ marginTop: '15px' }} >
				<Grid item style={{ color: '#707070', fontSize: '16px', fontWeight: 500 }} >
					<span>Create Business Today or Request to Join one</span>
				</Grid>
			</Grid>
			<Grid container width={'100%'} justifyContent={'center'} display={'flex'} flexDirection={isMuiMobile ? 'row' : "row"} alignContent={'center'} spacing={isMuiMobile ? 1 : 5} style={{ marginTop: '15px' }} >
				<Grid item>
					<Button style={{ padding: '5px', borderRadius: '5px', border: 'solid 1px #002543', textTransform: 'none', fontSize: isMuiMobile ? '14px' : '17px', fontWeight: 500, height: '61px', width: isMuiMobile ? '110px' : '191px', color: '#032541' }}>Join Business</Button>
				</Grid>
				<Grid item>
					<Button onClick={() => {
						dispatch(clearAdditions())
						navigate('/createBusiness')
					}} style={{ padding: '5px', borderRadius: '5px', border: 'solid 1px #002543', textTransform: 'none', fontSize: '17px', fontWeight: 500, height: '61px', width: isMuiMobile ? '110px' : '191px', color: '#fff', background: '#032541' }}> Create Business</Button>
				</Grid>
				<Grid item>
					<Button onClick={() => setIsQrOpen(!isOpenQR)} style={{ padding: '5px', display: 'flex', alignItems: 'center', borderRadius: '5px', background: "#f5f5f5", textTransform: 'none', fontSize: '17px', fontWeight: 500, height: '61px', width: isMuiMobile ? '120px' : '191px', color: '#032541' }}>
						<Box component={'img'} src={ScannerImg} alt="Scanner Image" />
						<span style={{ marginLeft: '10px' }}>Scan QR</span>
					</Button>
				</Grid>
			</Grid>
			<Grid item>
				{isOpenQR && <QRcodeScanner isOpenQR={isOpenQR} closeQr={closeQr}/>}
			</Grid>
			{isMuiMobile && 
			<Grid container justifyContent={'center'} style={{ marginTop: '15px' }}>
				<DataGrid
					components={{ NoRowsOverlay: NoRowsOverlay }}
					sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
					rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
					rowCount={pageState.total}
					loading={pageState.isLoading}
					pagination
					page={pageState.page - 1}
					pageSize={dataGridPageSize}
					paginationMode="server"
					onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					columns={BioColumns}
					rows={pageState?.data}
					getRowId={row => row?._id} />
			</Grid>}
		</div>
	);
}
